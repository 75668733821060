import React, { useState, useEffect } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import VoteEducationVersion1 from './VoteEducationVersion1';
import VoteEducationVersion2 from './VoteEducationVersion2';
import './VoteEducation.css'; // Create this CSS file

const VoteEducation = () => {
  // Function to get the current version from the URL
  const getCurrentVersionFromURL = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('before') ? 1 : 0;
  };

  const [currentVersion, setCurrentVersion] = useState(getCurrentVersionFromURL());

  useEffect(() => {
    // Update currentVersion state when the component mounts
    setCurrentVersion(getCurrentVersionFromURL());
  }, []);

  useEffect(() => {
    // Update the URL based on the current version
    const url = new URL(window.location.href);
    if (currentVersion === 1) {
      url.searchParams.set('before', 'true');
    } else {
      url.searchParams.delete('before');
    }
    window.history.replaceState({}, '', url.toString());
  }, [currentVersion]);

  const handlePrevious = () => {
    setCurrentVersion((prevVersion) => (prevVersion > 0 ? prevVersion - 1 : 1)); // Assuming there are 2 versions (0, 1)
  };

  const handleNext = () => {
    setCurrentVersion((prevVersion) => (prevVersion < 1 ? prevVersion + 1 : 0)); // Assuming there are 2 versions (0, 1)
  };

  const renderVersionContent = () => {
    switch (currentVersion) {
      case 0:
        return <VoteEducationVersion1 />;
      case 1:
        return <VoteEducationVersion2 />;
      default:
        return null;
    }
  };

  return (
    <div>
      {renderVersionContent()}
      {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
        <button onClick={handlePrevious} className="custom-button">
          <FaArrowLeft className="custom-icon" /> Previous
        </button>
        <button onClick={handleNext} className="custom-button">
          Next <FaArrowRight className="custom-icon" />
        </button>
      </div> */}
    </div>
  );
};

export default VoteEducation;