import React from 'react';
import ReferendumForm from './ReferendumForm';
import VoteDescription from './VoteDescription';
import { Card, CardBody, CardFooter, CardHeader, CardText, CardTitle } from 'reactstrap';

const ForeignPolicyReferendum = () => {
    const paragraphs: string[] = [
        "Should the armed forces initiate a targeted military operation in the neighboring country of X, aimed at mitigating threats to our national security, albeit with potential ramifications in terms of international law and our standing within the global community? This operation, though crucial according to some intelligence reports, might lead to substantial international backlash and possible sanctions. However, proponents argue that our national security interests take precedence. With this referendum, we seek the citizens' collective stance on navigating this delicate issue in our foreign policy.",
    ];


    return (<div>
        <ReferendumForm
            referendum="Foreign Policy Referendum"
            voteDescription={
                <Card className="mb-3">
                    <CardHeader tag="h5">Proposition 151: Special Military Operation for National Security</CardHeader>
                  <CardBody>
                    <p>This proposition asks voters to decide on the initiation of a targeted special military operation in the neighboring country of X. This action is proposed to address potential threats to our national security. It is based on certain intelligence reports suggesting the operation's crucial nature. However, it's important to note that such a course of action may result in potential violations of international law and may affect our standing in the global community. Consequences might include international backlash and potential sanctions. Proponents of this action argue that national security interests should take precedence over potential global fallout.</p>

                    <h6>Voting:</h6>
                    <p><strong>A "YES" vote</strong> means you are in favor of the targeted military operation in country X, considering it as a necessary step to ensure national security, despite possible international law implications and potential negative global reactions.</p>

                    <p><strong>A "NO" vote</strong> means you oppose the targeted military operation in country X, prioritizing adherence to international law and maintaining good standing within the global community over perceived national security threats.</p>
                    </CardBody>
                    <CardFooter />
                </Card>
            }
        /></div>)
};

export default ForeignPolicyReferendum;
