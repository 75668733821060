import React from 'react';
import { Card, CardTitle, CardText, Container, Row, Col } from 'reactstrap';
import { FaRegLightbulb, FaConnectdevelop, FaLock, FaSmileBeam, FaEye, FaGlobe, FaFistRaised } from 'react-icons/fa';

export const OurMission = () => {
  const missionContent = [
    {
      title: "Reimagining Democracy",
      text: "At Direct Democracy Corporation, we are pioneers dedicated to boldly transforming the democratic process. We harness advanced technological solutions to construct a robust, intuitive, and fully transparent platform that empowers people and encourages their direct involvement in politics. Our vision is a future where every citizen, regardless of background, has a voice and wields significant influence over decisions impacting their lives. We break down the walls hindering political involvement, championing accessibility that past democracies could only dream of.",
      icon: <FaRegLightbulb size={48} style={{ color: '#ff0000' }} />,
      bgColor: "light"
    },
    {
      title: "Decentralized Platform for Inclusive Democracy",
      text: "Our mission takes root in the implementation of a decentralized platform fortified by the infallible security of blockchain technology. This platform acts as a conduit, empowering citizens to engage directly in the decision-making process, amplifying their voices and views on critical issues. We aspire to foster a truly representative and fair democratic environment, capturing and reflecting the collective will of participants without bias or distortion.",
      icon: <FaConnectdevelop size={48} style={{ color: '#00ff00' }} />,
      bgColor: "light"
    },
    {
      title: "Security and Accessibility",
      text: "Security and accessibility are the pillars of our platform's operational efficacy. By harnessing peer-to-peer technology and public key cryptography, we ensure the absolute integrity of every vote cast. We offer an unprecedented level of trust in the democratic procedure, moving beyond traditional systems and creating a new standard in democratic participation.",
      icon: <FaLock size={48} style={{ color: '#0000ff' }} />,
      bgColor: "light"
    },
    {
      title: "Engaging User Experience",
      text: "We are committed to delivering an engaging and user-friendly experience. Our platform sparks a surge in civic involvement by crafting an inviting and intuitive platform. We strive to fuel participation from a broad range of demographics, nurturing an informed, vibrant, and deeply connected citizenry. Together, we revolutionize the voting process and reshape the political landscape.",
      icon: <FaSmileBeam size={48} style={{ color: '#17a2b8' }} />,
      bgColor: "light"
    },
    {
      title: "Transparency and Accountability",
      text: "Transparency and accountability are at the heart of every step on the democratic journey. We provide essential insights into voting trends and outcomes, empowering citizens with understanding and visibility. Our platform encourages accountability from elected officials, ensuring they are answerable to the people they serve.",
      icon: <FaEye size={48} style={{ color: '#00ffff' }} />,
      bgColor: "light"
    },
    {
      title: "Collaborative Global Shift",
      text: "The realization of our mission requires collaboration from diverse stakeholders, including governments, non-governmental organizations, and citizens. We are dedicated to working closely with these partners to catalyze a global shift towards a more inclusive, transparent, and accountable democracy. At Direct Democracy Corporation, we see ourselves as part of a movement to reshape democracy and pave the way for a more democratic and equitable world.",
      icon: <FaGlobe size={48} style={{ color: '#28a745' }} />,
      bgColor: "light"
    },
    {
      title: "Power to the People",
      text: "Direct Democracy Corporation is more than a company; we are agents of change. Our collective endeavor is to reshape the concept of democracy itself, paving a forward-thinking path towards a world where power truly belongs to the people. Join us on this transformative journey and together, let's create a future that is more democratic, equitable, and just.",
      icon: <FaFistRaised size={48} style={{ color: '#ff6600' }} />,
      bgColor: "light"
    },
  ];

  return (
    <Container className="my-4">
      <Row>
        {missionContent.map((section, index) => (
          <Col lg="4" md="6" sm="12" className="mb-4" key={index}>
            <Card body className={`h-100 shadow-sm bg-${section.bgColor}`}>
              <div className="d-flex mb-2 justify-content-center">
                {section.icon}
              </div>
              <CardTitle tag="h5" className="text-center">{section.title}</CardTitle>
              <CardText>{section.text}</CardText>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};