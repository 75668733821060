// Version1.js
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip as BarTooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Tooltip,
  RadarChart,
  PolarGrid,
  PolarRadiusAxis,
  PolarAngleAxis,
  Radar,
} from "recharts";
import { Card, CardBody, Row, Col, CardFooter, CardHeader } from "reactstrap";
import { FaRegThumbsDown, FaRegThumbsUp } from "react-icons/fa";
import Title from "./Title";
import {
  budgetDataEducation,
  resourceDataEducation,
  COLORSEducation,
} from "./VoteEducationData";
import { useMediaQuery } from "react-responsive";
import "./ProsCons.css";

const VoteEducationVersion2 = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  const isMobileExtraSmall = useMediaQuery({ query: "(max-width: 480px)" });
  const style = isMobile ? { fontSize: "0.8rem" } : {};
  const CardComponent = isMobile ? "div" : Card;
  const CardHeaderComponent = isMobile ? "h2" : CardHeader;
  const CardBodyComponent = isMobile ? "div" : CardBody;
  const CardFooterComponent = isMobile ? "div" : CardFooter;
  const renderLabel = (entry: any) => `${(entry.percent * 100).toFixed(0)}%`;

  return (
    <div>
      <CardComponent>
        <Title value={"Public Education Funding"} />
        <CardBody>
          <h5>The Proposal</h5>
          <p>
            The proposed referendum seeks to redistribute 2% of the
            administrative budget, totaling around $13.8 billion, to enhance
            educational resources and increase teachers' salaries. This
            strategic reallocation is anticipated to raise each teacher's pay by
            approximately $143 and provide a similar increase in the per-student
            allocation for educational resources.
          </p>
          <section>
            <h5>Impact Overview</h5>
            <section className="mb-4">
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={budgetDataEducation}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis tickFormatter={(tick) => `$${tick}B`} />
                  <BarTooltip formatter={(value) => `$${value}B`} />
                  <Legend />
                  <Bar
                    dataKey="initialBudget"
                    fill={COLORSEducation[0]}
                    name="Initial Budget"
                  />
                  <Bar
                    dataKey="newBudget"
                    fill={COLORSEducation[1]}
                    name="New Budget"
                  />
                </BarChart>
              </ResponsiveContainer>
            </section>{" "}
          </section>

          <h5 style={{ marginTop: 10 }}>Resource Distribution</h5>

          <p style={{ marginTop: "20px" }}>
            The chart below represents a suggested distribution of the $143 per
            student for various educational resources. For example, a larger
            portion of this amount may go towards 'Textbooks & Workbooks' and
            'Digital Resources', as compared to other resources. The actual
            distribution may vary based on the specific needs of each school and
            student.
          </p>

          {isMobile ? (
            <ResponsiveContainer
              width="100%"
              height={isMobileExtraSmall ? 400 : 330}
            >
              <PieChart>
                <Pie
                  data={resourceDataEducation}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#8884d8"
                  label={renderLabel}
                  //labelLine={false}
                >
                  {resourceDataEducation.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORSEducation[index % COLORSEducation.length]}
                    />
                  ))}
                </Pie>
                <Tooltip formatter={(value) => `${value}%`} />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <ResponsiveContainer width="100%" height={300}>
              <RadarChart outerRadius={90} data={resourceDataEducation}>
                <PolarGrid />
                <PolarAngleAxis dataKey="name" />
                <PolarRadiusAxis />
                <Radar
                  name="Resources"
                  dataKey="value"
                  stroke="#8884d8"
                  fill="#3f51b5"
                  fillOpacity={0.6}
                />
              </RadarChart>
            </ResponsiveContainer>
          )}

          <div className="pros-cons-section">
            <h5 className="section-title">Pros & Cons Analysis</h5>
            <Row className="pros-cons-row">
              <Col md={6} xs={12} className="pros-cons-col">
                <div className="pros">
                  <h6 className="pros-title">
                    Pros <FaRegThumbsUp className="icon" />
                  </h6>
                  <ul className="pros-list">
                    <li>
                      Improved educational resources: Enhanced materials,
                      technology, and facilities for better education.
                    </li>
                    <li>
                      Higher teacher salaries: Increased financial recognition
                      and incentive for educators.
                    </li>
                    <li>
                      Motivated teachers: Boosted morale and dedication for
                      better student outcomes.
                    </li>
                    <li>
                      Reduced administrative costs: Streamlined operations for
                      efficient resource allocation.
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={6} xs={12} className="pros-cons-col">
                <div className="cons">
                  <h6 className="cons-title">
                    Cons <FaRegThumbsDown className="icon" />
                  </h6>
                  <ul className="cons-list">
                    <li>
                      Administrative impact: Potential reduction in
                      administrative support services.
                    </li>
                    <li>
                      Budget constraints: Other areas of education may face
                      limited funding.
                    </li>
                    <li>
                      Equity concerns: Uniform reallocation may not address
                      existing disparities.
                    </li>
                    <li>
                      Long-term sustainability: Uncertainty about maintaining
                      increased funding levels.
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </CardBody>
        <CardFooter />
      </CardComponent>
    </div>
  );
};

export default VoteEducationVersion2;
