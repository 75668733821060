export interface CostDataItem {
    name: string;
    cost: number;
  }
  
export const data: CostDataItem[] = [
    { name: 'Personnel', cost: 30 },
    { name: 'Equipment', cost: 20 },
    { name: 'Logistics', cost: 15 },
    { name: 'Miscellaneous', cost: 5 },
  ];

export const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
