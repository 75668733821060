// src/components/HealthcareReferendum.tsx

import React, {FC, CSSProperties, useEffect} from 'react'
import {useMediaQuery} from 'react-responsive'
import {useLocation} from 'react-router-dom'
import ReferendumForm from './ReferendumForm'

const HealthcareReferendum: FC = () =>
{
  const isMobile = useMediaQuery({query: '(max-width: 768px)'})
  const location = useLocation()

  useEffect(() =>
  {
    window.scrollTo(0, 0)
  }, [location])

  // Styles
  const styles: {[key: string]: CSSProperties} = {
    container: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: isMobile ? '17.5px 14px' : '25px 20px',
      fontFamily: 'Helvetica Neue, sans-serif',
      color: '#000',
    },
    title: {
      fontSize: isMobile ? '28px' : '36px',
      fontWeight: 'bold',
      marginBottom: isMobile ? '14px' : '20px',
      textAlign: 'center',
    },
    sectionTitle: {
      fontSize: isMobile ? '24px' : '28px',
      fontWeight: 'bold',
      marginBottom: isMobile ? '14px' : '20px',
      textAlign: 'left',
    },
    text: {
      fontSize: isMobile ? '16px' : '18px',
      lineHeight: 1.6,
      marginBottom: isMobile ? '14px' : '20px',
      textAlign: 'left',
    },
    list: {
      listStyleType: 'disc',
      paddingLeft: '20px',
      marginBottom: isMobile ? '14px' : '20px',
    },
  }

  const voteDescription = (
    <div>
      <h1 style={styles.title}>Proposition 139: Affordable Medication Act</h1>
      <p style={styles.text}>
        The Affordable Medication Act is a proposed law that seeks to reform current practices regarding prescription medication pricing. If passed, this proposition will:
      </p>
      <ul style={styles.list}>
        <li>
          Empower the relevant authorities to directly negotiate drug prices with pharmaceutical companies, potentially lowering the cost of medications.
        </li>
        <li>
          Allow for the safe and regulated importation of prescription drugs from approved foreign sources, introducing more competition into the market.
        </li>
        <li>
          Implement a cap on out-of-pocket drug costs for patients, alleviating the financial strain faced by individuals in need of essential medications.
        </li>
        <li>
          Require full transparency in drug pricing, compelling pharmaceutical companies to disclose their pricing strategies and justifications for pricing decisions.
        </li>
        <li>
          Regulate the annual increase in drug prices to prevent undue price hikes.
        </li>
      </ul>
      <p style={styles.text}>
        This proposed law aims to make prescription medications more affordable and accessible for all citizens.
      </p>
      <p style={styles.text}>
        Shall the Affordable Medication Act be adopted?
      </p>
      <p style={styles.text}>
        <strong>A "YES" vote</strong> supports permitting the state to negotiate drug prices directly with pharmaceutical companies, authorizing the safe importation of drugs, implementing a cap on out-of-pocket drug costs, requiring pricing transparency, and controlling annual price increases.
      </p>
      <p style={styles.text}>
        <strong>A "NO" vote</strong> opposes these provisions and maintains the current system for prescription medication pricing and distribution.
      </p>
    </div>
  )

  return (
    <div style={styles.container}>
      <ReferendumForm
        referendum="Healthcare Referendum"
        voteDescription={voteDescription}
      />
    </div>
  )
}

export default HealthcareReferendum
