import { Card, CardBody, CardFooter, CardHeader, CardText, CardTitle, Col, Container, Row } from 'reactstrap';
import ReferendumForm from './ReferendumForm';
import VoteDescription from './VoteDescription';

const EnvironmentReferendum = () => {
    const paragraphs: string[] = [
        "Shall legislation be enacted to establish the 'Private Property Environmental Protection Act', which would prohibit the direct or indirect pollution of private property by any entity or individual? This legislation would confer upon property owners the right to seek legal recourse in instances where their property has been affected adversely by pollution. The objective of this act would be to safeguard public health, protect the aesthetic value of properties, and promote an environmentally conscious society. This comprehensive legislation would include stringent regulatory oversight, penalties commensurate with the level of environmental harm caused, and enforce compulsory clean-up mandates for confirmed offenders. This act aims to balance individual property rights with the broader societal interest in maintaining a clean, healthy environment.",
    ];

    return <div>
        <ReferendumForm
            referendum="Environment Referendum"
            voteDescription={
                <Row>
                    <Col md={12}>
                        <Card className="mb-3">
                            <CardHeader tag="h5">Proposition 147: Private Property Environmental Protection Act</CardHeader>
                            <CardBody>
                                <CardText>
                                    Should the Private Property Environmental Protection Act be enacted? This act prohibits direct or indirect pollution of private property by any entity or individual, enabling property owners to pursue legal action in instances of pollution-related property damage. It aims to protect public health, preserve property aesthetic value, and foster an environmentally conscious society through stringent regulatory oversight, proportional penalties for environmental harm, and obligatory clean-up for proven offenders.
                                </CardText>

                                <CardTitle tag="h6">A YES vote on this measure means:</CardTitle>
                                <CardText>
                                    You support the enactment of the Private Property Environmental Protection Act, which would prohibit pollution of private property, enable legal recourse for property owners, enforce penalties for environmental harm, and mandate clean-ups by proven offenders.
                                </CardText>
                                <CardTitle tag="h6">A NO vote on this measure means:</CardTitle>
                                <CardText>
                                    You are against the enactment of the Private Property Environmental Protection Act, thereby not altering existing laws related to property pollution.
                                </CardText>
                            </CardBody>
                            <CardFooter />
                        </Card>
                    </Col>
                </Row>
            }
        />
    </div>
};

export default EnvironmentReferendum;
