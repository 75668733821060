// src/components/VoteHealthCare.tsx

import React, {FC, CSSProperties, useState, useEffect} from 'react'
import {useMediaQuery} from 'react-responsive'
import {useLocation} from 'react-router-dom'
import
{
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts'
import {FaRegThumbsUp, FaRegThumbsDown, FaSearchPlus} from 'react-icons/fa'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import healthcarediagram from '../../images/healthcarediagram.jpg'
import Title from './Title'

interface DataItem
{
  name: string
  currentPrice: number
  projectedPrice: number
}

interface VoteHealthCareProps
{
  title: string
  description: string
  data: DataItem[]
}

const VoteHealthCare: FC<VoteHealthCareProps> = ({title, description, data}) =>
{
  const isMobile = useMediaQuery({query: '(max-width: 768px)'})
  const location = useLocation()

  useEffect(() =>
  {
    window.scrollTo(0, 0)
  }, [location])

  const [isOpen, setIsOpen] = useState(false)

  // Styles
  const styles: {[key: string]: CSSProperties} = {
    container: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: isMobile ? '17.5px 14px' : '25px 20px',
      fontFamily: 'Helvetica Neue, sans-serif',
      color: '#000',
    },
    title: {
      fontSize: isMobile ? '28px' : '36px',
      fontWeight: 'bold',
      marginBottom: isMobile ? '14px' : '20px',
      textAlign: 'center',
    },
    sectionTitle: {
      fontSize: isMobile ? '24px' : '28px',
      fontWeight: 'bold',
      marginBottom: isMobile ? '14px' : '20px',
    },
    text: {
      fontSize: isMobile ? '16px' : '18px',
      lineHeight: 1.6,
      marginBottom: isMobile ? '14px' : '20px',
    },
    chartContainer: {
      marginBottom: isMobile ? '14px' : '20px',
    },
    prosConsContainer: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      gap: '20px',
      marginBottom: isMobile ? '14px' : '20px',
    },
    prosConsColumn: {
      flex: 1,
    },
    prosConsTitle: {
      fontSize: isMobile ? '20px' : '24px',
      fontWeight: 'bold',
      marginBottom: isMobile ? '10px' : '12px',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
    list: {
      listStyleType: 'disc',
      paddingLeft: '20px',
    },
    diagramContainer: {
      position: 'relative',
      cursor: 'pointer',
      marginBottom: isMobile ? '14px' : '20px',
    },
    diagramImage: {
      width: '100%',
      height: 'auto',
    },
    overlayText: {
      position: 'absolute',
      bottom: '10px',
      left: '50%',
      transform: 'translateX(-50%)',
      color: '#fff',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      padding: '5px 10px',
      borderRadius: '5px',
      fontSize: isMobile ? '14px' : '16px',
      pointerEvents: 'none',
    },
  }

  const renderCustomizedLabel = (props: any) =>
  {
    const {x, y, width, value} = props
    const radius = 10

    return (
      <text
        x={x + width / 2}
        y={y - radius}
        fill="#8884d8"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {`$${value}`}
      </text>
    )
  }

  const impactOverview = () => (
    <div style={styles.chartContainer}>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data} margin={{top: 5, bottom: 5}}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis domain={[0, 'dataMax + 50']} tickFormatter={(tick) => `$${tick}`} />
          <Tooltip formatter={(value) => `$${value}`} />
          <Legend />
          <Bar
            dataKey="currentPrice"
            name="Current Price"
            fill="#8884d8"
            label={renderCustomizedLabel}
          />
          <Bar
            dataKey="projectedPrice"
            name="Projected Price"
            fill="#82ca9d"
            label={renderCustomizedLabel}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )

  const renderProsCons = () => (
    <div style={styles.prosConsContainer}>
      <div style={styles.prosConsColumn}>
        <h3 style={styles.prosConsTitle}>
          Pros <FaRegThumbsUp />
        </h3>
        <ul style={styles.list}>
          <li>May significantly reduce drug prices.</li>
          <li>Could increase accessibility to medications for low-income individuals.</li>
          <li>
            May promote transparency and accountability in the pharmaceutical industry.
          </li>
          <li>
            Potential to stimulate competition and innovation in the drug market.
          </li>
          <li>Could lead to overall better public health outcomes.</li>
        </ul>
      </div>
      <div style={styles.prosConsColumn}>
        <h3 style={styles.prosConsTitle}>
          Cons <FaRegThumbsDown />
        </h3>
        <ul style={styles.list}>
          <li>Potential backlash from pharmaceutical companies.</li>
          <li>Risk of reduced investment in research and development.</li>
          <li>
            Possible issues related to quality control and safety with imported drugs.
          </li>
          <li>Potential increase in taxpayer's burden.</li>
        </ul>
      </div>
    </div>
  )

  return (
    <div style={styles.container}>
      {/* <Title value={title} /> */}
      <h1 style={styles.title}>The Proposal</h1>
      <p style={styles.text}>
        The proposed referendum seeks to implement the Affordable Medication Act, which
        includes several key features aimed at reducing prescription medication costs and
        increasing accessibility to essential drugs.
      </p>

      <h2 style={styles.sectionTitle}>Key Features</h2>
      <ul style={styles.list}>
        <li>
          Direct negotiation with pharmaceutical companies for fair drug prices.
        </li>
        <li>
          Permitting safe importation of prescription drugs from vetted international
          sources.
        </li>
        <li>
          Implementing a cap on out-of-pocket drug costs to protect consumers.
        </li>
        <li>
          Mandating transparency in drug pricing, eliminating hidden costs and fostering
          accountability.
        </li>
        <li>
          Regulating annual price increases on medications to prevent unjustified price
          hikes.
        </li>
      </ul>

      <h2 style={styles.sectionTitle}>Voting Information</h2>
      <p style={styles.text}>
        <strong>A "YES" vote</strong> supports the implementation of the Affordable
        Medication Act, aiming to reduce prescription medication costs and increase drug
        accessibility.
      </p>
      <p style={styles.text}>
        <strong>A "NO" vote</strong> opposes the implementation of the Affordable
        Medication Act, maintaining the current state of prescription medication pricing
        and accessibility.
      </p>

      <h2 style={styles.sectionTitle}>Impact on Prescription Medication Costs</h2>
      <p style={styles.text}>
        The bar chart below compares the current and projected prices for four commonly
        used prescription medications, assuming the Affordable Medication Act is passed.
        These projections offer a snapshot of the potential financial benefits for
        consumers:
      </p>
      {impactOverview()}

      {renderProsCons()}

      <h2 style={styles.sectionTitle}>Impact Diagram</h2>
      <p style={styles.text}>
        The following diagram illustrates the potential impact of the new law on
        prescription medication costs and accessibility:
      </p>
      <div style={styles.diagramContainer} onClick={() => setIsOpen(true)}>
        <img
          src={healthcarediagram}
          alt="Affordable Medication Act Impact Diagram"
          style={styles.diagramImage}
        />
        <div style={styles.overlayText}>Click to enlarge</div>
      </div>
      {isOpen && (
        <Lightbox mainSrc={healthcarediagram} onCloseRequest={() => setIsOpen(false)} />
      )}
    </div>
  )
}

export default VoteHealthCare
