import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import VoteHealthCare from './VoteHealthCare';
import './VoteEducation.css'; 
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import VoteHealthCareVersion2 from './VoteHealthCareVersion2';

const HealthCarePage: React.FC = () => {
  const healthCareData = [
    { name: 'Drug A', currentPrice: 100, projectedPrice: 80 },
    { name: 'Drug B', currentPrice: 150, projectedPrice: 120 },
    { name: 'Drug C', currentPrice: 200, projectedPrice: 160 },
    { name: 'Drug D', currentPrice: 250, projectedPrice: 200 },
  ];

  // Function to get the current version from the URL
  const getCurrentVersionFromURL = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('before') ? 1 : 0;
  };

  const [currentVersion, setCurrentVersion] = useState(getCurrentVersionFromURL());

  useEffect(() => {
    // Update currentVersion state when the component mounts
    setCurrentVersion(getCurrentVersionFromURL());
  }, []);

  useEffect(() => {
    // Update the URL based on the current version
    const url = new URL(window.location.href);
    if (currentVersion === 1) {
      url.searchParams.set('before', 'true');
    } else {
      url.searchParams.delete('before');
    }
    window.history.replaceState({}, '', url.toString());
  }, [currentVersion]);

  const handlePrevious = () => {
    setCurrentVersion((prevVersion) => (prevVersion > 0 ? prevVersion - 1 : 1)); // Assuming there are 2 versions (0, 1)
  };

  const handleNext = () => {
    setCurrentVersion((prevVersion) => (prevVersion < 1 ? prevVersion + 1 : 0)); // Assuming there are 2 versions (0, 1)
  };

  const renderVersionContent = () => {
    switch (currentVersion) {
      case 0:
        return (
          <VoteHealthCare
            title="Public Health Care Funding"
            description="This upcoming referendum asks whether we should increase funds for public health care. The proposed increase is significant and could have a substantial impact on our healthcare system. Here's what the new funding distribution would look like:"
            data={healthCareData}
          />
        );
      case 1:
        return <VoteHealthCareVersion2 title="Public Health Care Funding" description="This upcoming referendum asks whether we should increase funds for public health care. The proposed increase is significant and could have a substantial impact on our healthcare system. Here's what the new funding distribution would look like:" data={healthCareData} />;
      default:
        return null;
    }
  };

  return (
    <div>
      {renderVersionContent()}
      {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
        <button onClick={handlePrevious} className="custom-button">
          <FaArrowLeft className="custom-icon" /> Previous
        </button>
        <button onClick={handleNext} className="custom-button">
          Next <FaArrowRight className="custom-icon" />
        </button>
      </div> */}
    </div>
  );
};

export default HealthCarePage;