import React from "react";
import { FaRegThumbsDown, FaRegThumbsUp } from "react-icons/fa";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  CardFooter,
  Col,
  Row,
} from "reactstrap";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Bar,
  BarChart,
} from "recharts";
import { useMediaQuery } from "react-responsive";
import Title from "./Title";
import { dataHousing } from "./VoteHousingData";
import "./ProsCons.css";

const VoteHousingVersion1: React.FC = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  const CardComponent = isMobile ? "div" : Card;
  const CardHeaderComponent = isMobile ? "h2" : CardHeader;

  const renderProsCons = () => (
    <div className="pros-cons-section">
      <h5 className="section-title">Pros and Cons Analysis</h5>

      <Row className="pros-cons-row">
        <Col md={6} xs={12} className="pros-cons-col">
          <div className="pros">
            <h6 className="pros-title">
              Pros <FaRegThumbsUp className="icon" />
            </h6>
            <ul className="pros-list">
              <li>
                Proactive and Solution-Oriented Approach: By offering homeless
                individuals resources and rehabilitation, this referendum aims
                to provide a path toward stability.
              </li>
              <li>
                Potential Reduction in Homelessness: This could potentially
                reduce homelessness and related social issues over time, relying
                on the effectiveness of the provided resources and programs.
              </li>
              <li>
                Encourages Community Participation and Service: The referendum
                seeks to foster community involvement by offering homeless
                individuals opportunities to participate in community
                improvement projects.
              </li>
              <li>
                Integration and Belonging: This could facilitate integration,
                create a sense of belonging, and help them build a stronger
                connection with the community.
              </li>
            </ul>
          </div>
        </Col>
        <Col md={6} xs={12} className="pros-cons-col">
          <div className="cons">
            <h6 className="cons-title">
              Cons <FaRegThumbsDown className="icon" />
            </h6>
            <ul className="cons-list">
              <li>
                Voluntary Participation: The effectiveness of community service
                as a tool for integration may depend on the willingness and
                motivation of the individuals involved.
              </li>
              <li>
                Varied Outcomes: This could lead to varying outcomes and is
                contingent on individuals' commitment and community response.
              </li>
              <li>
                Availability and Accessibility: Some might question the
                availability and accessibility of the proposed government-funded
                communities and resources.
              </li>
              <li>
                Potential Supply Issues: This could potentially lead to issues
                if demand exceeds supply and may not address the root causes of
                homelessness, such as lack of affordable housing or mental
                health services.
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </div>
  );

  const impactOverview = () => (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={dataHousing}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="Total Homeless" stackId="1" fill="#8884d8" />
        <Bar dataKey="Relocated Individuals" stackId="1" fill="#82ca9d" />
        <Bar
          dataKey="Community Service Participants"
          stackId="1"
          fill="#ffc658"
        />
      </BarChart>
    </ResponsiveContainer>
  );

  return (
    <div>
      <CardComponent>
        <Title value={"Housing Referendum"} />
        <CardBody>
          <h5>The Proposal</h5>
          <section className="mb-4">
            <p className="text-sm">
              The Shelter or Service Referendum aims to address the issue of
              homelessness in our communities through a supportive and inclusive
              approach. It offers two options to homeless individuals: the
              option to voluntarily move to government-funded communities with
              resources, or participate in community improvement projects.
            </p>
          </section>

          <section className="mb-4">
            <h6 className="font-weight-bold mb-2">Voting Information</h6>
            <p className="text-sm mb-2">
              <strong>A "YES" vote</strong> supports the implementation of the
              Shelter or Service Referendum, aiming to provide resources and
              community improvement opportunities to homeless individuals.
            </p>
            <p className="text-sm">
              <strong>A "NO" vote</strong> opposes the implementation of the
              referendum, maintaining the current state of homelessness support
              and services.
            </p>
          </section>

          <h5>Impact Overview</h5>
          <section className="mb-4">
            <h6 className="font-weight-bold mb-2">
              Homeless Population Projection
            </h6>
            <p className="text-sm">
              The chart below projects changes in the homeless population for
              the next year based on the implementation of the Shelter or
              Service Referendum. It shows the potential decrease in the total
              homeless population and the distribution among relocated and
              community service participating individuals.
            </p>
            {impactOverview()}
          </section>

          {renderProsCons()}
        </CardBody>
        <CardFooter />
      </CardComponent>
    </div>
  );
};

export default VoteHousingVersion1;
