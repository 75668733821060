import React from 'react'

const PrivacyPolicyScreen: React.FC = () =>
{
    return (
        <div style={styles.container}>
            <div style={styles.content}>
                <div style={styles.scrollContainer}>
                    <h2 style={styles.sectionTitle}>VotePilot Privacy Policy</h2>
                    <p style={styles.introParagraph}>
                        Last Updated: September 26, 2024
                    </p>
                    <p style={styles.paragraph}>
                        Welcome to VotePilot! Your privacy is important to us, and we are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and protect your data when you use the VotePilot application ("App"). By using the App, you agree to the terms outlined in this Privacy Policy.
                    </p>

                    <h3 style={styles.sectionTitle}>1. Information We Collect</h3>
                    <p style={styles.paragraph}>
                        We collect minimal personal information necessary to provide you with our services. The types of data we collect include:
                    </p>

                    <p style={styles.paragraph}>
                        <strong style={styles.subSection}>1.1 Personal Information</strong>
                        <br />
                        <strong style={styles.boldText}>Account Information:</strong> When you create an account, we collect your username and email address. This information is required to set up your account and provide access to the App.
                    </p>

                    <p style={styles.paragraph}>
                        <strong style={styles.subSection}>1.2 Device Information</strong>
                        <br />
                        <strong style={styles.boldText}>Device Identifier:</strong> We collect a unique device identifier generated via React Native Expo. This identifier is securely created and stored on your device and is utilized to enforce our one-account-per-device policy and to manage and prevent duplicate accounts. This identifier does not correspond to the hardware ID of your device and is used exclusively for account management purposes.
                    </p>

                    <p style={styles.paragraph}>
                        <strong style={styles.subSection}>1.3 Usage Data</strong>
                        <br />
                        <strong style={styles.boldText}>Referendum Participation:</strong> When you create, vote on, or otherwise engage with a referendum, we collect information related to your activity, including but not limited to the content of the referendum and your voting selections. Referendums you create shall only be accessible to individuals whom you explicitly invite, and such individuals will only gain access to view and participate in the referendum upon accepting the invitation. The information collected is used solely for the purpose of facilitating and maintaining the proper functioning of the App.
                    </p>

                    <p style={styles.paragraph}>
                        <strong style={styles.subSection}>1.4 Community Information</strong>
                        <br />
                        <strong style={styles.boldText}>Community Participation:</strong> When you create or participate in a community, we collect information related to your community activities, including community membership, invitations sent and received, and any content shared within the community. You can create a maximum of one community with up to four members, including yourself. If you prefer not to receive community invitations or be added to any communities, you can adjust your profile settings to make your account not visible to others.
                    </p>

                    <h3 style={styles.sectionTitle}>2. How We Use Your Information</h3>
                    <p style={styles.paragraph}>
                        We use your information solely for the following purposes:
                    </p>

                    <p style={styles.paragraph}>
                        <strong style={styles.boldText}>To Provide and Improve Our Services:</strong> To operate, maintain, and enhance the App's functionality and user experience.
                    </p>

                    <p style={styles.paragraph}>
                        <strong style={styles.boldText}>To Manage Your Account:</strong> To manage your account using your username, email address, and device ID, ensuring compliance with our policies and providing customer support.
                    </p>

                    <p style={styles.paragraph}>
                        <strong style={styles.boldText}>To Facilitate Referendums:</strong> To manage referendums you create or participate in, ensuring the integrity and accuracy of the voting process.
                    </p>

                    <p style={styles.paragraph}>
                        <strong style={styles.boldText}>To Facilitate Community Features:</strong> To manage communities you create or participate in, including sending invitations, managing membership, and allowing you to exit communities. Upon deletion of a community, all associated data, including invitations and user data within the community, will be permanently erased.
                    </p>

                    <p style={styles.paragraph}>
                        <strong style={styles.boldText}>To Prevent Duplicate Accounts:</strong> To enforce our one-account-per-device policy and prevent multiple accounts from being created on the same device.
                    </p>

                    <h3 style={styles.sectionTitle}>3. How We Share Your Information</h3>
                    <p style={styles.paragraph}>
                        We do not sell, rent, or share your personal information with third parties for their marketing purposes. Your data is kept confidential and is only shared in the following circumstances:
                    </p>

                    <p style={styles.paragraph}>
                        <strong style={styles.boldText}>With Your Consent:</strong> We may share your information if you provide explicit consent.
                    </p>

                    <p style={styles.paragraph}>
                        <strong style={styles.boldText}>For Legal Compliance:</strong> We may disclose your information if required to do so by law or in response to a legal process, such as a court order or subpoena.
                    </p>

                    <p style={styles.paragraph}>
                        <strong style={styles.boldText}>To Protect Rights and Safety:</strong> We may disclose information to protect the rights, property, and safety of VotePilot, our users, or the public, as required or permitted by law.
                    </p>

                    <h3 style={styles.sectionTitle}>4. Your Privacy Rights</h3>
                    <p style={styles.paragraph}>
                        We respect your privacy rights and provide you with control over your personal data:
                    </p>

                    <p style={styles.paragraph}>
                        <strong style={styles.boldText}>Delete Your Account:</strong> You may delete your account at any time. Once deleted, all associated data, including votes cast and account information, will be permanently erased from our servers with no residual metadata left behind.
                    </p>

                    <p style={styles.paragraph}>
                        <strong style={styles.boldText}>Manage Community Participation:</strong> You have the option to exit any community you are currently in at any time. If you delete a community you have created, all data associated with that community, including invitations and user data within the community, will be permanently deleted from our servers with no residual metadata left behind.
                    </p>

                    <p style={styles.paragraph}>
                        <strong style={styles.boldText}>Opt-Out of Community Invitations:</strong> If you prefer not to receive community invitations, you can adjust your profile settings to make your account not visible to others.
                    </p>

                    <p style={styles.paragraph}>
                        <strong style={styles.boldText}>Manage Referendum Participation:</strong> You have the option to delete any referendum you have created at any time. If you delete a referendum, all data associated with that referendum, including invitations and user data within, will be permanently deleted from our servers with no residual metadata left behind.
                    </p>

                    <p style={styles.paragraph}>
                        <strong style={styles.boldText}>Opt-Out of Referendum Invitations:</strong> If you prefer not to receive invitations to participate in referendums, you can adjust your profile settings to make your account not visible to others. This will prevent other users from sending you referendum invitations.
                    </p>

                    <p style={styles.paragraph}>
                        Please note that users cannot modify their personal information (username or email address) once it has been provided. To change this information, users must delete their existing account and create a new one with the updated details.
                    </p>

                    <h3 style={styles.sectionTitle}>5. Data Security</h3>
                    <p style={styles.paragraph}>
                        We take the security of your personal information seriously and implement appropriate technical and organizational measures to protect it against unauthorized access, disclosure, alteration, or destruction. All data is stored securely in the Azure cloud infrastructure with encryption in transit and at rest.
                    </p>

                    <h3 style={styles.sectionTitle}>6. Data Retention</h3>
                    <p style={styles.paragraph}>
                        We retain your personal information only for as long as necessary to provide the services you have requested or for other essential purposes such as complying with our legal obligations, resolving disputes, and enforcing our agreements. If you delete your account, we will permanently delete all associated data with no residual metadata left behind.
                    </p>

                    <h3 style={styles.sectionTitle}>7. Changes to This Privacy Policy</h3>
                    <p style={styles.paragraph}>
                        We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. If we make significant changes, we will notify you through the App. Your continued use of the App after such modifications will constitute your acknowledgment of the updated policy.
                    </p>

                    <h3 style={styles.sectionTitle}>8. Contact Us</h3>
                    <p style={styles.paragraph}>
                        If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:{' '}
                        <a href="mailto:contact@directdemocracy.global" style={styles.hyperlink}>
                            contact@directdemocracy.global
                        </a>.
                    </p>

                    <p style={styles.paragraph}>
                        By using VotePilot, you acknowledge that you have read and understood this Privacy Policy and agree to its terms.
                    </p>
                </div>
                <div style={styles.footerSpacer} />
            </div>
        </div>
    )
}

const styles: {[key: string]: React.CSSProperties} = {
    container: {
        width: '100%',
        minHeight: '100vh',
        background: 'transparent',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    content: {
        width: '100%',
        padding: '2rem',
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    },
    scrollContainer: {
        paddingBottom: '20px',
    },
    sectionTitle: {
        fontSize: '20px',
        fontWeight: 700,
        marginTop: '30px',
        marginBottom: '15px',
        color: '#1C1C1E',
        letterSpacing: '0.5px',
    },
    subSection: {
        fontSize: '18px',
        fontWeight: 600,
        color: '#2E2E2E',
        marginTop: '20px',
        marginBottom: '10px',
    },
    introParagraph: {
        fontSize: '17px',
        color: '#333',
        lineHeight: '26px',
        marginBottom: '15px',
        textAlign: 'justify',
    },
    paragraph: {
        fontSize: '17px',
        marginTop: '10px',
        color: '#3A3A3C',
        lineHeight: '26px',
        textAlign: 'left',
        letterSpacing: '0.2px',
    },
    footerSpacer: {
        height: '20px',
    },
    hyperlink: {
        color: '#007AFF',
        textDecoration: 'underline',
    },
    boldText: {
        fontWeight: 'bold',
    },
}

export default PrivacyPolicyScreen