import React, { createContext, useState, useContext, ReactNode } from 'react';

type VoteOption = "YES" | "NO";

interface Vote {
  referendum: string;
  vote: VoteOption;
}

interface VoteContextData {
  votes: Vote[];
  addVote: (referendum: string, vote: VoteOption) => void;
  getVotes: () => Vote[];
}

const VoteContext = createContext<VoteContextData | undefined>(undefined);

interface VoteProviderProps {
  children: ReactNode;
}

const VoteContextProvider: React.FC<VoteProviderProps> = ({ children }) => {
  const [votes, setVotes] = useState<Vote[]>([]);

  const addVote = (referendum: string, vote: VoteOption) => {
    setVotes([...votes, { referendum, vote }]);
  };

  const getVotes = () => votes;

  return (
    <VoteContext.Provider value={{ votes, addVote, getVotes }}>
      {children}
    </VoteContext.Provider>
  );
};

export { VoteContext, VoteContextProvider };
