// src/components/VoteEducationVersion1.tsx

import React, {FC, CSSProperties, useState, useEffect} from 'react'
import {useMediaQuery} from 'react-responsive'
import {useLocation} from 'react-router-dom'
import
{
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip as BarTooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Tooltip,
  RadarChart,
  PolarGrid,
  PolarRadiusAxis,
  PolarAngleAxis,
  Radar,
} from 'recharts'
import {FaRegThumbsDown, FaRegThumbsUp, FaSearchPlus} from 'react-icons/fa'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import educationdiagram from '../../images/educationdiagram.jpg'
import Title from './Title'
import
{
  budgetDataEducation,
  resourceDataEducation,
  COLORSEducation,
} from './VoteEducationData'

const VoteEducationVersion1: FC = () =>
{
  const isMobile = useMediaQuery({query: '(max-width: 768px)'})
  const location = useLocation()

  useEffect(() =>
  {
    window.scrollTo(0, 0)
  }, [location])

  const [isOpen, setIsOpen] = useState(false)

  // Styles
  const styles: {[key: string]: CSSProperties} = {
    container: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: isMobile ? '17.5px 14px' : '25px 20px',
      fontFamily: 'Helvetica Neue, sans-serif',
      color: '#000',
    },
    title: {
      fontSize: isMobile ? '28px' : '36px',
      fontWeight: 'bold',
      marginBottom: isMobile ? '14px' : '20px',
      textAlign: 'center',
    },
    sectionTitle: {
      fontSize: isMobile ? '24px' : '28px',
      fontWeight: 'bold',
      marginBottom: isMobile ? '14px' : '20px',
    },
    text: {
      fontSize: isMobile ? '16px' : '18px',
      lineHeight: 1.6,
      marginBottom: isMobile ? '14px' : '20px',
    },
    chartContainer: {
      marginBottom: isMobile ? '14px' : '20px',
    },
    prosConsContainer: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      gap: '20px',
      marginBottom: isMobile ? '14px' : '20px',
    },
    prosConsColumn: {
      flex: 1,
    },
    prosConsTitle: {
      fontSize: isMobile ? '20px' : '24px',
      fontWeight: 'bold',
      marginBottom: isMobile ? '10px' : '12px',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
    list: {
      listStyleType: 'disc',
      paddingLeft: '20px',
    },
    diagramContainer: {
      position: 'relative',
      cursor: 'pointer',
      marginBottom: isMobile ? '14px' : '20px',
    },
    diagramImage: {
      width: '100%',
      height: 'auto',
    },
    overlayText: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: '#fff',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      padding: '10px 20px',
      borderRadius: '8px',
      fontSize: isMobile ? '16px' : '18px',
    },
  }

  const renderLabel = (entry: any) => `${(entry.percent * 100).toFixed(0)}%`

  const impactOverview = isMobile ? (
    <ResponsiveContainer width="100%" height={isMobile ? 400 : 330}>
      <PieChart>
        <Pie
          data={resourceDataEducation}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={80}
          label={renderLabel}
        >
          {resourceDataEducation.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORSEducation[index % COLORSEducation.length]} />
          ))}
        </Pie>
        <Tooltip formatter={(value) => `${value}%`} />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  ) : (
    <ResponsiveContainer width="100%" height={300}>
      <RadarChart outerRadius={90} data={resourceDataEducation}>
        <PolarGrid />
        <PolarAngleAxis dataKey="name" />
        <PolarRadiusAxis />
        <Radar
          name="Resources"
          dataKey="value"
          stroke="#8884d8"
          fill="#3f51b5"
          fillOpacity={0.6}
        />
      </RadarChart>
    </ResponsiveContainer>
  )

  const renderProsCons = () => (
    <div style={styles.prosConsContainer}>
      <div style={styles.prosConsColumn}>
        <h3 style={styles.prosConsTitle}>
          Pros <FaRegThumbsUp />
        </h3>
        <ul style={styles.list}>
          <li>
            Improved educational resources: Enhanced materials, technology, and facilities for better education.
          </li>
          <li>
            Higher teacher salaries: Increased financial recognition and incentive for educators.
          </li>
          <li>
            Motivated teachers: Boosted morale and dedication for better student outcomes.
          </li>
          <li>
            Reduced administrative costs: Streamlined operations for efficient resource allocation.
          </li>
        </ul>
      </div>
      <div style={styles.prosConsColumn}>
        <h3 style={styles.prosConsTitle}>
          Cons <FaRegThumbsDown />
        </h3>
        <ul style={styles.list}>
          <li>
            Administrative impact: Potential reduction in administrative support services.
          </li>
          <li>
            Budget constraints: Other areas of education may face limited funding.
          </li>
          <li>
            Equity concerns: Uniform reallocation may not address existing disparities.
          </li>
          <li>
            Long-term sustainability: Uncertainty about maintaining increased funding levels.
          </li>
        </ul>
      </div>
    </div>
  )

  return (
    <div style={styles.container}>
      {/* <Title value="Public Education Funding" /> */}
      <h1 style={styles.title}>The Proposal</h1>
      <p style={styles.text}>
        The proposed referendum seeks to redistribute 2% of the administrative budget, totaling around $13.8 billion, to enhance educational resources and increase teachers' salaries. This strategic reallocation is anticipated to raise each teacher's pay by approximately $143 and provide a similar increase in the per-student allocation for educational resources.
      </p>

      <h2 style={styles.sectionTitle}>Voting Information</h2>
      <p style={styles.text}>
        <strong>A "YES" vote</strong> approves the redistribution of 2% of the administrative budget to increase educational resources and teachers' salaries.
      </p>
      <p style={styles.text}>
        <strong>A "NO" vote</strong> opposes the redistribution, maintaining the current budget allocation.
      </p>

      <h2 style={styles.sectionTitle}>Reallocation of Funds: Administrative to Education Budget</h2>
      <p style={styles.text}>
        The following bar chart illustrates the reallocation of funds from the administrative budget to the education budget:
      </p>
      <div style={styles.chartContainer}>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={budgetDataEducation}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis tickFormatter={(tick) => `$${tick}B`} />
            <BarTooltip formatter={(value) => `$${value}B`} />
            <Legend />
            <Bar dataKey="initialBudget" fill={COLORSEducation[0]} name="Initial Budget" />
            <Bar dataKey="newBudget" fill={COLORSEducation[1]} name="New Budget" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      <h2 style={styles.sectionTitle}>Impact Diagram</h2>
      <p style={styles.text}>
        The following diagram illustrates the potential impact of different budget allocation strategies on the overall education funding. The diagram shows the outcomes of redistributing or maintaining the budget:
      </p>
      <div style={styles.diagramContainer} onClick={() => setIsOpen(true)}>
        <img
          src={educationdiagram}
          alt="Education Budget Diagram"
          style={styles.diagramImage}
        />
        <div style={styles.overlayText}>Click to enlarge</div>
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={educationdiagram}
          onCloseRequest={() => setIsOpen(false)}
        />
      )}

      {!isMobile && (
        <>
          <h2 style={styles.sectionTitle}>Resource Distribution</h2>
          <p style={styles.text}>
            The chart below represents a suggested distribution of the $143 per student for various educational resources. For example, a larger portion of this amount may go towards 'Textbooks & Workbooks' and 'Digital Resources', as compared to other resources. The actual distribution may vary based on the specific needs of each school and student.
          </p>
          <div style={styles.chartContainer}>{impactOverview}</div>
        </>
      )}

      {renderProsCons()}
    </div>
  )
}

export default VoteEducationVersion1
