// Breadcrumb.tsx
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import BreadcrumbNav from '../Breadcrumb';
import { AppPaths } from '../../AppPaths';
import { CardHeader } from 'reactstrap';

interface BreadcrumbProps {
    value: string;
}

const Title: React.FC<BreadcrumbProps> = ({ value }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 1000px)' });
    
    let result = <CardHeader>
        <BreadcrumbNav items={[{ path: AppPaths.ReferendumPage, label: "Prototype" }, { path: "", label: value }]} />
    </CardHeader>

    if (isMobile)
        result = <h2>{value}</h2>

    return (
        result
    );
};

export default Title;
