// src/components/TaxReferendum.tsx

import React, {FC, CSSProperties, useEffect} from 'react'
import {useMediaQuery} from 'react-responsive'
import {useLocation} from 'react-router-dom'
import ReferendumForm from './ReferendumForm'

const TaxReferendum: FC = () =>
{
  const isMobile = useMediaQuery({query: '(max-width: 768px)'})
  const location = useLocation()

  useEffect(() =>
  {
    window.scrollTo(0, 0)
  }, [location])

  // Styles
  const styles: {[key: string]: CSSProperties} = {
    container: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: isMobile ? '17.5px 14px' : '25px 20px',
      fontFamily: 'Helvetica Neue, sans-serif',
      color: '#000',
    },
    title: {
      fontSize: isMobile ? '28px' : '36px',
      fontWeight: 'bold',
      marginBottom: isMobile ? '14px' : '20px',
      textAlign: 'center',
    },
    sectionTitle: {
      fontSize: isMobile ? '24px' : '28px',
      fontWeight: 'bold',
      marginBottom: isMobile ? '14px' : '20px',
    },
    text: {
      fontSize: isMobile ? '16px' : '18px',
      lineHeight: 1.6,
      marginBottom: isMobile ? '14px' : '20px',
    },
    list: {
      listStyleType: 'disc',
      paddingLeft: '20px',
      marginBottom: isMobile ? '14px' : '20px',
    },
  }

  const voteDescription = (
    <div>
      <h1 style={styles.title}>
        Proposition 137: Local Sales Tax Increase for Public Infrastructure Improvement
      </h1>
      <p style={styles.text}>
        This proposition, if approved, will increase the local sales tax rate by 0.5% for the next decade. The additional tax revenue, estimated at $50 million per year, will be specifically allocated towards the maintenance and improvement of public infrastructure in our community.
      </p>

      <h2 style={styles.sectionTitle}>Proposal:</h2>
      <ul style={styles.list}>
        <li>
          <strong>Enactment of a Sales Tax Increase:</strong> The local sales tax rate will be increased by 0.5% for a period of ten years, starting from January 1, 2025, and ending on December 31, 2034.
        </li>
        <li>
          <strong>Dedication of Revenues:</strong> All additional revenues generated by this increase, estimated at $50 million per annum, will be expressly earmarked for the maintenance and improvement of public infrastructure.
        </li>
        <li>
          <strong>Use of Funds:</strong> Funds collected from this tax increase will be used for projects such as repairing roads and bridges; upgrading public transportation; improving parks, sidewalks, and other public spaces; enhancing utilities such as water and sewage systems.
        </li>
        <li>
          <strong>Oversight and Transparency:</strong> An annual audit will be conducted to ensure that all revenues are spent as intended, and a report on the use of funds will be made publicly available.
        </li>
      </ul>

      <h2 style={styles.sectionTitle}>Voting:</h2>
      <p style={styles.text}>
        <strong>A "YES" vote</strong> approves the sales tax increase of 0.5% for ten years, with the generated revenue to be used solely for public infrastructure maintenance and improvement.
      </p>
      <p style={styles.text}>
        <strong>A "NO" vote</strong> opposes the sales tax increase of 0.5% for ten years, maintaining the current sales tax rate.
      </p>
    </div>
  )

  return (
    <div style={styles.container}>
      <ReferendumForm
        referendum="Local Sales Tax Increase for Public Infrastructure Improvement"
        voteDescription={voteDescription}
      />
    </div>
  )
}

export default TaxReferendum
