import React from 'react'
import {Container, Row, Col} from 'reactstrap'
import {NavMenu} from './NavMenu'
import {useMediaQuery} from 'react-responsive'

// Define the type for props
interface LayoutProps
{
  children: React.ReactNode
}

export const Layout: React.FC<LayoutProps> = ({children}) =>
{
  const year = new Date().getFullYear()

  // Media query to detect if the device is a mobile device (less than 768px width)
  const isMobile = useMediaQuery({query: '(max-width: 768px)'})

  // Define NavMenu height based on device type (adjust these values to match your actual NavMenu height)
  const navMenuHeight = 75 // Assuming the same height for simplicity

  return (
    <div
      className="d-flex flex-column min-vh-100"
      style={{backgroundColor: '#f5f5f5'}}
    >
      {/* Navigation Menu */}
      <NavMenu />

      {/* Scrollable content area */}
      <Container
        className="flex-grow-1"
        style={{
          marginTop: `${navMenuHeight + 20}px`, // Adjusted marginTop
          paddingBottom: isMobile ? '20px' : '70px', // Adjust paddingBottom based on footer presence
        }}
      >
        <Row>
          <Col sm="12" md={{size: 10, offset: 1}}>
            {children}
          </Col>
        </Row>
      </Container>

      {/* Conditionally render the footer only if not on mobile */}
      {!isMobile && (
        <footer
          className="fixed-bottom text-center p-2 bg-light border-top"
          style={{
            fontSize: '16px',
            backgroundColor: '#f5f5f5',
            width: '100%',
            position: 'fixed',
            bottom: 0,
            left: 0,
            textAlign: 'center',
          }}
        >
          <p className="mb-0">
            &copy; {year} Direct Democracy Corporation. All rights reserved.
          </p>
        </footer>
      )}
    </div>
  )
}