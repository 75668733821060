import React, {useState, useEffect} from 'react'
import {FiPhone, FiMail, FiLinkedin} from 'react-icons/fi'
import {FaPhone, FaEnvelope, FaLinkedin} from 'react-icons/fa' // Importing FontAwesome icons
import {useLocation} from 'react-router-dom'

export const ContactUs: React.FC = () =>
{
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768)

  // Handle responsiveness
  useEffect(() =>
  {
    const handleResize = () =>
    {
      setIsMobile(window.innerWidth <= 768)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const location = useLocation()

  useEffect(() =>
  {
    window.scrollTo(0, 0) // Resets the scroll position to the top
  }, [location])

  // Styles
  const styles: {[key: string]: React.CSSProperties} = {
    container: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '25px 20px',
      fontFamily: 'Helvetica Neue, sans-serif',
    },
    contactSection: {
      textAlign: 'center',
      marginBottom: '60px',
    },
    contactItems: {
      display: 'flex',
      justifyContent: 'center',
      gap: '40px',
      marginTop: '20px',
    },
    iconLink: {
      fontSize: '24px',
      textDecoration: 'none',
      transition: 'color 0.3s',
      color: 'inherit',
    },
    teamSection: {
      marginTop: '60px',
    },
    teamContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: '60px',
    },
    categoryTitle: {
      fontSize: isMobile ? '22px' : '26px',
      fontWeight: 'bold',
      marginBottom: '40px',
      textAlign: 'center',
    },
    teamMember: {
      textAlign: 'center',
      padding: '20px 0',
    },
    quoteContainer: {
      maxWidth: '630px',
      margin: '0 auto',
      padding: '0 20px',
    },
    quoteText: {
      fontStyle: 'italic',
      fontSize: isMobile ? '18px' : '20px',
      lineHeight: 1.5,
      marginBottom: '10px',
      textAlign: 'center',
    },
    attribution: {
      fontSize: isMobile ? '16px' : '18px',
      fontWeight: 'bold',
      marginTop: '10px',
    },
    teamTitle: {
      fontSize: isMobile ? '14px' : '16px',
      color: '#555',
      marginBottom: '10px',
    },
    iconRow: {
      display: 'flex',
      justifyContent: 'center',
      gap: '30px',
      marginTop: '10px',
    },
  }

  // Team Members Data with Manual Line Breaks
  const founders = [
    {
      name: 'W. Patrick McPhilamy, III',
      title: 'Chief Executive Officer',
      email: 'patrick.mcphilamy@directdemocracyco.com',
      phone: '+1-626-799-8000',
      linkedin: 'https://www.linkedin.com/in/w-patrick-mcphilamy-iii-a54b1137/',
      quote:
        'A world shaped by the hands of the many stands stronger, not because it is perfect, but because it seeks to grow ever wiser.',
      quoteLines: [
        'A world shaped by the hands of the many stands stronger,',
        'not because it is perfect, but because it seeks to grow ever wiser.',
      ],
    },
    {
      name: 'Robert Karapetyan',
      title: 'Lead Software Engineer',
      email: 'robert.karapetyan@directdemocracyco.com',
      phone: '+1-323-570-5151',
      linkedin: 'https://www.linkedin.com/in/robertkarapetyan/',
      quote: 'A leader’s worth is not in how he endures, but in how his vision walks without him.',
      quoteLines: [
        'A leader’s worth is not in how he endures,',
        'but in how his vision walks without him.',
      ],
    },
    {
      name: 'Mickey Maravic',
      title: 'Chief Engagement Officer',
      email: 'mickey.maravic@directdemocracyco.com',
      phone: '+1-323-633-0903',
      linkedin: 'https://www.linkedin.com/in/mickey-maravic-3a9424286/',
      quote: 'It’s the curious mind, not the loudest voice, that shapes the best path forward.',
      quoteLines: [
        'It’s the curious mind, not the loudest voice,',
        'that shapes the best path forward.',
      ],
    },
  ]

  const executives = [
    {
      name: 'Carson Ellsworth',
      title: 'Chief Technology Officer',
      email: 'carson.ellsworth@directdemocracyco.com',
      phone: '+1-725-287-1405',
      linkedin: 'https://www.linkedin.com/in/cellsworth/',
      quote: 'In the mundane and overlooked, greatness waits for someone to care enough to make a difference.',
      quoteLines: ['In the mundane and overlooked, greatness waits', 'for someone to care enough to make a difference.'],
    },
    {
      name: 'Benjamin Valadez',
      title: 'Chief Development Officer',
      email: 'benjamin.valadez@directdemocracyco.com',
      phone: '+0-000-000-0000',
      linkedin: 'https://www.linkedin.com/in/benjamin-valadez-027426159/',
      quote: 'The true greatness of humanity is not found in the mastery of one, but in the wisdom shared by many.',
      quoteLines: ['The true greatness of humanity is not found', 'in the mastery of one, but in the wisdom shared by many.'],
    },
  ]

  // Company LinkedIn URL
  const companyLinkedIn = 'https://www.linkedin.com/company/direct-democracy-corporation'

  return (
    <div style={styles.container}>
      {/* Get in Touch Section */}
      <section style={styles.contactSection}>
        {/* Icons Only for Minimalism */}
        <div style={styles.contactItems}>
          <a href="tel:+13236330903" style={styles.iconLink} aria-label="Call us">
            <FaPhone />
          </a>
          <a href="mailto:contact@directdemocracy.global" style={styles.iconLink} aria-label="Email us">
            <FaEnvelope />
          </a>
          <a
            href={companyLinkedIn}
            target="_blank"
            rel="noopener noreferrer"
            style={styles.iconLink}
            aria-label="Visit our LinkedIn page"
          >
            <FaLinkedin />
          </a>
        </div>
      </section>

      {/* Our Founders Section */}
      <section style={styles.teamSection}>
        <h2 style={styles.categoryTitle}>Our Founders</h2>
        <div style={styles.teamContainer}>
          {founders.map((member, index) => (
            <div key={index} style={styles.teamMember}>
              {/* Quote Container */}
              <div style={styles.quoteContainer}>
                {isMobile ? (
                  <p style={styles.quoteText}>"{member.quote}"</p>
                ) : (
                  <p style={styles.quoteText}>
                    “{member.quoteLines[0]}
                    <br />
                    {member.quoteLines[1]}”
                  </p>
                )}
                <p style={styles.attribution}>– {member.name}</p>
                <p style={styles.teamTitle}>{member.title}</p>
              </div>
              {/* Icon Row */}
              <div style={styles.iconRow}>
                <a href={`mailto:${member.email}`} style={styles.iconLink} aria-label={`Email ${member.name}`}>
                  <FiMail />
                </a>
                <a href={`tel:${member.phone}`} style={styles.iconLink} aria-label={`Call ${member.name}`}>
                  <FiPhone />
                </a>
                <a
                  href={member.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={styles.iconLink}
                  aria-label={`${member.name}'s LinkedIn`}
                >
                  <FiLinkedin />
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Our Executives Section */}
      <section style={styles.teamSection}>
        <h2 style={styles.categoryTitle}>Our Executives</h2>
        <div style={styles.teamContainer}>
          {executives.map((member, index) => (
            <div key={index} style={styles.teamMember}>
              {/* Quote Container */}
              <div style={styles.quoteContainer}>
                {isMobile ? (
                  <p style={styles.quoteText}>"{member.quote}"</p>
                ) : (
                  <p style={styles.quoteText}>
                    “{member.quoteLines[0]}
                    <br />
                    {member.quoteLines[1]}”
                  </p>
                )}
                <p style={styles.attribution}>– {member.name}</p>
                <p style={styles.teamTitle}>{member.title}</p>
              </div>
              {/* Icon Row */}
              <div style={styles.iconRow}>
                <a href={`mailto:${member.email}`} style={styles.iconLink} aria-label={`Email ${member.name}`}>
                  <FiMail />
                </a>
                <a href={`tel:${member.phone}`} style={styles.iconLink} aria-label={`Call ${member.name}`}>
                  <FiPhone />
                </a>
                <a
                  href={member.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={styles.iconLink}
                  aria-label={`${member.name}'s LinkedIn`}
                >
                  <FiLinkedin />
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  )
}