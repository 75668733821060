import React from 'react'

// UserAgreementScreen Component for React Web
const UserAgreementScreen: React.FC = () =>
{
    return (
        <div style={styles.container}>
            <div style={styles.content}>
                <div style={styles.scrollContainer}>
                    <h2 style={styles.sectionTitle}>VotePilot Terms of Service</h2>
                    <p style={styles.paragraph}>Last Updated: September 26, 2024</p>

                    <p style={styles.paragraph}>
                        Welcome to VotePilot! Before using our application, please carefully review these Terms of Service ("Terms"). By accessing or using VotePilot, you agree to be bound by these Terms. If you do not agree to these Terms, you may not use the application.
                    </p>

                    <h3 style={styles.sectionTitle}>1. Acceptance of Terms</h3>
                    <p style={styles.paragraph}>
                        By creating an account or otherwise using VotePilot, you accept and agree to comply with these Terms, including any additional terms and conditions and policies referenced herein or available by hyperlink. These Terms apply to all users of the application, including without limitation users who are browsers, vendors, customers, and contributors of content.
                    </p>

                    <h3 style={styles.sectionTitle}>2. Changes to the Terms of Service</h3>
                    <p style={styles.paragraph}>
                        VotePilot reserves the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion. Continued use of the application after any such changes shall constitute your consent to such changes.
                    </p>

                    <h3 style={styles.sectionTitle}>3. Eligibility</h3>
                    <p style={styles.paragraph}>
                        To use VotePilot, you must be at least 18 years of age or the age of majority in your jurisdiction. By using the application, you represent and warrant that you have the right, authority, and capacity to enter into these Terms and to abide by all of the terms and conditions set forth herein.
                    </p>

                    <h3 style={styles.sectionTitle}>4. Account Registration</h3>
                    <p style={styles.paragraph}>
                        Users must create an account to use certain features of the application. When creating an account, you agree to provide accurate, current, and complete information and to update this information as necessary. Only one account per device is permitted. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. You must notify VotePilot immediately of any unauthorized use of your account or any other breach of security. You may not share your account credentials with others or use another user's account without permission.
                        <br />
                        Please note, if you forget your password, we do not offer a password reset option. In such cases, you will need to delete your account, which will permanently remove all associated data, after which you can create a new account on your device.
                    </p>

                    <h3 style={styles.sectionTitle}>5. User Responsibilities</h3>
                    <p style={styles.paragraph}>
                        By using VotePilot, you agree to:
                    </p>
                    <ul style={styles.list}>
                        <li>Use the application in accordance with all applicable laws and regulations.</li>
                        <li>Refrain from using the application for any illegal or unauthorized purpose.</li>
                        <li>Not engage in any activity that interferes with or disrupts the application or the servers and networks connected to the application.</li>
                        <li>Not use the application to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability.</li>
                    </ul>

                    <h3 style={styles.sectionTitle}>6. User-Generated Content, Referendums, and Communities</h3>
                    <p style={styles.paragraph}>
                        Users may generate referendums and create communities via the application using the provided tools, including Large Language Models (LLMs). All referendum descriptions and community content are moderated by OpenAI's Moderation API to ensure appropriateness.
                    </p>
                    <p style={styles.paragraph}>
                        <strong style={styles.boldText}>Referendums:</strong> Users are limited to three active referendums at any given time and have a three-day period to invite participants for each created referendum. Referendums you create are only accessible to individuals whom you explicitly invite, and such individuals will only gain access upon accepting the invitation. Users cannot modify referendums once they are generated and cannot edit or alter the referendum during or after creation, including during the review process. VotePilot does not bear any responsibility for the political weight or implications of any user-generated referendums.
                    </p>
                    <p style={styles.paragraph}>
                        <strong style={styles.boldText}>Communities:</strong> Users can create a maximum of one community with up to four members, including themselves. Users can only be added to a community upon accepting an explicit invitation. If you prefer not to receive community invitations or be added to any communities, you can adjust your profile settings to make your account not visible to others. Users have the option to exit any community they are currently in at any time. Upon deletion of a community, all associated data, including invitations and user data within the community, will be permanently erased.
                    </p>

                    <h3 style={styles.sectionTitle}>7. Account Deletion and Data Privacy</h3>
                    <p style={styles.paragraph}>
                        Users may delete their accounts at any time. Account deletion will result in the removal of all associated data, including votes, referendums, communities, and invitations. No metadata will be retained following deletion. Upon account deletion, all associated data is completely erased from our Azure cloud infrastructure, ensuring no recovery or retention of user data. All communications between the user and the system are encrypted to protect user privacy.
                    </p>

                    <h3 style={styles.sectionTitle}>8. Intellectual Property</h3>
                    <p style={styles.paragraph}>
                        All content available on VotePilot, including but not limited to text, graphics, logos, button icons, images, and software, is the property of VotePilot or its licensors and is protected by international copyright laws. You may not use, reproduce, distribute, or create derivative works from any content on the application without express written permission from VotePilot.
                    </p>

                    <h3 style={styles.sectionTitle}>9. Termination</h3>
                    <p style={styles.paragraph}>
                        VotePilot reserves the right to suspend or terminate your account and access to the application at any time, without notice, for conduct that we believe violates these Terms or is harmful to other users of the application, us, or third parties, or for any other reason in our sole discretion.
                    </p>

                    <h3 style={styles.sectionTitle}>10. Limitation of Liability</h3>
                    <p style={styles.paragraph}>
                        To the maximum extent permitted by law, VotePilot shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, use, goodwill, or other intangible losses, resulting from:
                    </p>
                    <ul style={styles.list}>
                        <li>Your use or inability to use the application;</li>
                        <li>Any unauthorized access to or use of our servers and/or any personal information stored therein;</li>
                        <li>Any bugs, viruses, Trojan horses, or the like that may be transmitted to or through our application by any third party;</li>
                        <li>Any errors or omissions in any content or for any loss or damage incurred as a result of your use of any content posted, emailed, transmitted, or otherwise made available through the application.</li>
                    </ul>

                    <h3 style={styles.sectionTitle}>11. Indemnification</h3>
                    <p style={styles.paragraph}>
                        You agree to indemnify, defend, and hold harmless VotePilot and our affiliates, officers, directors, agents, partners, and employees from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your breach of these Terms, your use of the application, or your violation of any law or the rights of a third party.
                    </p>

                    <h3 style={styles.sectionTitle}>12. Governing Law</h3>
                    <p style={styles.paragraph}>
                        These Terms shall be governed by and construed in accordance with the laws of the State of California, without regard to its conflict of law principles.
                    </p>

                    <h3 style={styles.sectionTitle}>13. Dispute Resolution</h3>
                    <p style={styles.paragraph}>
                        Any disputes arising out of or related to these Terms or the application shall be resolved through binding arbitration conducted in California, except that you may assert claims in small claims court if your claims qualify.
                    </p>

                    <h3 style={styles.sectionTitle}>14. Miscellaneous</h3>
                    <p style={styles.paragraph}>
                        If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect. These Terms constitute the entire agreement between you and VotePilot regarding the use of the application and supersede any prior agreements. Any failure by VotePilot to enforce any right or provision of these Terms will not be considered a waiver of those rights.
                    </p>

                    <h3 style={styles.sectionTitle}>15. Contact Information</h3>
                    <p style={styles.paragraph}>
                        If you have any questions about these Terms, please contact us at:{' '}
                        <a href="mailto:contact@directdemocracy.global" style={styles.hyperlink}>
                            contact@directdemocracy.global
                        </a>.
                    </p>

                    <p style={{...styles.paragraph, ...styles.lastParagraph}}>
                        By using VotePilot, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.
                    </p>
                </div>
                <div style={styles.footerSpacer} />
            </div>
        </div>
    )
}

// Styles Object
const styles: {[key: string]: React.CSSProperties} = {
    container: {
        width: '100%',
        minHeight: '100vh',
        background: 'transparent',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '20px',
        paddingBottom: '20px',
    },
    content: {
        width: '100%',
        maxWidth: '800px',
        padding: '2rem',
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        overflowY: 'auto',
        maxHeight: '90vh',
    },
    scrollContainer: {
        paddingBottom: '20px',
    },
    sectionTitle: {
        fontSize: '24px',
        fontWeight: 700,
        marginTop: '30px',
        marginBottom: '15px',
        color: '#1C1C1E',
        letterSpacing: '0.5px',
    },
    paragraph: {
        fontSize: '16px',
        marginTop: '10px',
        color: '#3A3A3C',
        lineHeight: '1.6',
        textAlign: 'left',
        letterSpacing: '0.2px',
    },
    list: {
        fontSize: '16px',
        marginTop: '10px',
        color: '#3A3A3C',
        lineHeight: '1.6',
        textAlign: 'left',
        letterSpacing: '0.2px',
        paddingLeft: '20px',
    },
    hyperlink: {
        color: '#007AFF',
        textDecoration: 'underline',
    },
    boldText: {
        fontWeight: 'bold',
    },
    lastParagraph: {
        marginBottom: '30px',
    },
    footerSpacer: {
        height: '20px',
    },
}

export default UserAgreementScreen