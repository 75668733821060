export const dataHousing = [
    {
      name: 'Current Year',
      'Total Homeless': 5000,
      'Relocated Individuals': 1000,
      'Community Service Participants': 500,
    },
    {
      name: 'Projected Next Year',
      'Total Homeless': 3700,
      'Relocated Individuals': 2000,
      'Community Service Participants': 800,
    },
  ];

  // VoteHousingData.ts

export const dataHousingV2 = [
  { name: 'Q1', 'Total Homeless': 1000, 'Relocated Individuals': 300, 'Community Service Participants': 200 },
  { name: 'Q2', 'Total Homeless': 900, 'Relocated Individuals': 350, 'Community Service Participants': 250 },
  { name: 'Q3', 'Total Homeless': 800, 'Relocated Individuals': 400, 'Community Service Participants': 300 },
  { name: 'Q4', 'Total Homeless': 700, 'Relocated Individuals': 450, 'Community Service Participants': 350 }
];

export const pieDataHousing = [
  { name: 'Proactive Approach', value: 400, type: 'pro' },
  { name: 'Community Participation', value: 300, type: 'pro' },
  { name: 'Voluntary Participation', value: 300, type: 'con' },
  { name: 'Availability Issues', value: 200, type: 'con' }
];

export const pieDataHousingPros = [
  { name: 'Proactive Approach', value: 400 },
  { name: 'Community Participation', value: 300 },
  { name: 'Resource Availability', value: 200 }
];

export const pieDataHousingCons = [
  { name: 'Voluntary Participation', value: 300 },
  { name: 'Availability Issues', value: 200 },
  { name: 'Potential Overload', value: 100 }
];