import React from 'react';
import { newsData } from '../data/NewsData';

export type NewsItem = {
    title: string;
    content: React.ReactNode;
    date: string;
}

interface NewsCardProps {
    newsItem: NewsItem;
}

const NewsCard: React.FC<NewsCardProps> = ({ newsItem }) => {
    return (
        <div className="card">
            <div className="card-body">
                <h5 className="card-title">{newsItem.title}</h5>
                <div className="card-text">{newsItem.content}</div>
            </div>
            <div className="card-footer">
                <small className="text-muted">Posted on {newsItem.date}</small>
            </div>
        </div>
    );
};

type NewsAndUpdatesProps = {
    newsData: NewsItem[];
}

const NewsAndUpdatesPage: React.FC<NewsAndUpdatesProps> = ({ newsData }) => {
    return (
        <div className="container">
            <div className="row">
                {newsData.map((newsItem, index) => (
                    <div className="col-md-6 my-3" key={index}>
                        <NewsCard newsItem={newsItem} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export const NewsAndUpdates = () => {
    return <NewsAndUpdatesPage newsData={newsData} />
}

