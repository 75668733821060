import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip as BarTooltip,
  CartesianGrid,
  Legend,
  RadarChart,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  LabelList,
  LabelProps,
  TooltipProps,
  Tooltip,
  LineChart,
  Line,
  ComposedChart,
} from "recharts";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  CardFooter,
  Button,
} from "reactstrap";
import { FaRegThumbsDown, FaRegThumbsUp } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import Title from "./Title";
import {
  adjustedImpactData,
  COLORSEnvironment,
  FinancialData,
  mockFinancialData,
  mockPositiveImpactData,
} from "./VoteEnvironmentData";
import "./ProsCons.css";

const VoteEnvironmentVersion2: React.FC = () => {
  const renderLabel = (entry: FinancialData) =>
    `${entry.name}: ${entry.value}%`;
  const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  const CardComponent = isMobile ? "div" : Card;
  const CardHeaderComponent = isMobile ? "h2" : CardHeader;

  const [showChart, setShowChart] = useState(false);

  return (
    <CardComponent>
      <Title value={"Environment Referendum"} />
      <CardBody>
        <h5>The Proposal</h5>
        <p>
          The referendum proposes the Private Property Environmental Protection
          Act to prohibit pollution on private property, giving owners legal
          recourse for damages. It aims to safeguard public health, preserve
          property aesthetics, and promote an environmentally conscious society.
          The act includes strict regulations, penalties, and mandatory clean-up
          mandates. Its goal is to balance property rights with the importance
          of a clean environment.
        </p>

        <h5 style={{ marginTop: 15 }}>Financial Implications Analysis</h5>
        <p>
          <strong>Financial Implications:</strong> Businesses could face
          increased costs due to clean-up mandates, fines, and regulatory
          compliance, potentially affecting their profitability.
        </p>
        <p>
          <strong>Enforcement Challenges:</strong> Proving indirect pollution
          and quantifying harm could be challenging, possibly burdening the
          judicial system.
        </p>
        <p>
          <strong>Unintended Consequences:</strong> The act could potentially
          incentivize businesses to move to jurisdictions with less stringent
          environmental protections.
        </p>

        <p>
          The pie chart below represents the estimated percentage distribution
          of potential financial costs if the act is passed:
        </p>
        <div style={{ width: "100%", height: 300 }}>
          <ResponsiveContainer>
            <PieChart>
              <Pie
                data={mockFinancialData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={100}
                fill="#8884d8"
                label={
                  isMobile
                    ? ({ name, value }) => `${value}%`
                    : ({ name, value }) => renderLabel({ name, value })
                }
              >
                {mockFinancialData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORSEnvironment[index % COLORSEnvironment.length]}
                  />
                ))}
              </Pie>
              <BarTooltip formatter={(value) => `${value}%`} />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>

        <h5>Potential Impact Analysis</h5>

        <p>
          The chart below shows both immediate and long-term impacts of the
          proposed act across various sectors. The bars reflect the direct
          consequences, with positive values signaling benefits and negatives
          indicating challenges. The line graph overlays these to predict trends
          over time, providing a nuanced forecast of the potential implications
          of the legislation.
        </p>

        {isMobile ? (
          showChart ? (
            <div
              style={{
                width: "100%",
                overflowX: "scroll",
                overflowY: "hidden",
              }}
            >
              <style>
                {`
      ::-webkit-scrollbar {
        width: 0.4rem;
        height: 0.4rem;
      }
      ::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 0.2rem;
      }
      `}
              </style>
              <ComposedChart
                width={1400}
                height={300}
                data={adjustedImpactData}
              >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis
                  dataKey="subject"
                  interval={0}
                  tick={{ fontSize: 12 }}
                  angle={-30}
                  textAnchor="end"
                  height={80}
                />
                <YAxis domain={[-100, 100]} />
                <Bar dataKey="Pro" barSize={30} fill="#413ea0" />
                <Bar dataKey="Con" barSize={30} fill="#c42c2c" />
                <Line type="monotone" dataKey="Impact" stroke="#ff7300" />
                <Tooltip />
                <Legend
                  layout="horizontal"
                  verticalAlign="bottom"
                  align={isMobile ? "left" : "center"}
                />
              </ComposedChart>
            </div>
          ) : (
            <Button
              color="primary"
              className="custom-button"
              style={{
                marginBottom: "10px",
                padding: "0.25rem 0.5rem",
                fontSize: "0.875rem",
              }}
              onClick={() => setShowChart(true)}
            >
              View Chart
            </Button>
          )
        ) : (
          <div
            style={{ width: "100%", overflowX: "scroll", overflowY: "hidden" }}
          >
            <style>
              {`
      ::-webkit-scrollbar {
        width: 0.4rem;
        height: 0.4rem;
      }
      ::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 0.2rem;
      }
      `}
            </style>
            <ComposedChart
              width={1400}
              height={300}
              data={mockPositiveImpactData}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis
                dataKey="subject"
                interval={0}
                tick={{ fontSize: 12 }}
                angle={-30}
                textAnchor="end"
                height={80}
              />
              <YAxis domain={[-100, 100]} />
              <Bar dataKey="Pro" barSize={30} fill="#413ea0" />
              <Bar dataKey="Con" barSize={30} fill="#c42c2c" />
              <Line type="monotone" dataKey="Impact" stroke="#ff7300" />
              <Tooltip />
              <Legend
                layout="horizontal"
                verticalAlign="bottom"
                align={isMobile ? "left" : "center"}
              />
            </ComposedChart>
          </div>
        )}

        <div className="pros-cons-section">
          <h5 className="section-title">Pros and Cons Analysis</h5>
          <Row className="pros-cons-row">
            <Col md={6} xs={12} className="pros-cons-col">
              <div className="pros">
                <h6 className="pros-title">
                  Pros <FaRegThumbsUp className="icon" />
                </h6>
                <ul className="pros-list">
                  <li>
                    Public Health Protection: Enhances community wellbeing by
                    mitigating health risks associated with pollution.
                  </li>
                  <li>
                    Property Value Preservation: Prevents potential decline in
                    property value due to environmental harm.
                  </li>
                  <li>
                    Environmental Accountability: Introduces stringent
                    regulations and penalties for those who pollute, encouraging
                    more responsible behaviors.
                  </li>
                  <li>
                    Legal Recourse: Provides property owners with the ability to
                    seek justice if their property is adversely affected by
                    pollution.
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={6} xs={12} className="pros-cons-col">
              <div className="cons">
                <h6 className="cons-title">
                  Cons <FaRegThumbsDown className="icon" />
                </h6>
                <ul className="cons-list">
                  <li>
                    Regulatory Burden: Could lead to an increased bureaucracy
                    due to the need for more regulatory oversight.
                  </li>
                  <li>
                    Economic Impact: May negatively affect businesses,
                    especially small enterprises, due to the cost of compliance
                    or penalties.
                  </li>
                  <li>
                    Implementation Challenges: Difficulties in enforcement and
                    proving indirect pollution could occur.
                  </li>
                  <li>
                    Potential for Abuse: There might be a risk of frivolous
                    lawsuits or misuse of the act to settle personal disputes.
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </CardBody>
      <CardFooter />
    </CardComponent>
  );
};

export default VoteEnvironmentVersion2;
