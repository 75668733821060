import React, {useContext} from 'react'
import {Container, Table, Badge} from 'reactstrap'
import {VoteContext} from './VoteContext'
import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'

enum VoteOption
{
  YES = 'YES',
  NO = 'NO',
}

const VoteHistory: React.FC = () =>
{
  const voteContext = useContext(VoteContext)
  const votes = voteContext?.getVotes() ?? []
  const location = useLocation()


  useEffect(() =>
  {
    window.scrollTo(0, 0) // Resets the scroll position to the top
  }, [location])

  return (
    <Container>
      <h3 className="text-center my-4">Your Vote History</h3>
      {votes.length > 0 ? (
        <Table responsive striped>
          <thead>
            <tr>
              <th>#</th>
              <th>Referendum</th>
              <th>Vote</th>
            </tr>
          </thead>
          <tbody>
            {votes.map((vote, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{vote.referendum}</td>
                <td>
                  <Badge color={vote.vote === VoteOption.YES ? 'success' : 'danger'}>
                    {vote.vote}
                  </Badge>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p className="text-center">You haven't voted yet!</p>
      )}
    </Container>
  )
}

export default VoteHistory
