import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  CardFooter,
  Col,
  Row,
} from "reactstrap";
import { FaRegThumbsUp, FaRegThumbsDown } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import { AppPaths } from "../../AppPaths";
import BreadcrumbNav from "../Breadcrumb";
import Title from "./Title";
import "./ProsCons.css";

interface DataItem {
  name: string;
  currentPrice: number;
  projectedPrice: number;
}

interface VoteHealthCareProps {
  title: string;
  description: string;
  data: DataItem[];
}

const renderCustomizedLabel = (props: any) => {
  const { x, y, width, value } = props;
  const radius = 10;

  return (
    <text
      x={x + width / 2}
      y={y - radius}
      fill="#8884d8"
      textAnchor="middle"
      dominantBaseline="middle"
    >
      {`$${value}`}
    </text>
  );
};

const VoteHealthCareVersion2: React.FC<VoteHealthCareProps> = ({
  title,
  description,
  data,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  const CardComponent = isMobile ? "div" : Card;
  const CardHeaderComponent = isMobile ? "h2" : CardHeader;

  return (
    <CardComponent>
      <Title value={title} />
      <CardBody>
        <h5>The Proposal</h5>
        <p>{description}</p>
        <h5>Understanding the Affordable Medication Act</h5>
        <h6>Key Features</h6>
        <ul>
          <li>
            Direct negotiation with pharmaceutical companies for fair drug
            prices.
          </li>
          <li>
            Permitting safe importation of prescription drugs from vetted
            international sources.
          </li>
          <li>
            Implementing a cap on out-of-pocket drug costs to protect consumers.
          </li>
          <li>
            Mandating transparency in drug pricing, eliminating hidden costs and
            fostering accountability.
          </li>
          <li>
            Regulating annual price increases on medications to prevent
            unjustified price hikes.
          </li>
        </ul>

        <h5>Impact on Prescription Medication Costs</h5>
        <p>
          The bar chart below compares the current and projected prices for four
          commonly used prescription medications, assuming the Affordable
          Medication Act is passed. These projections offer a snapshot of the
          potential financial benefits for consumers:
        </p>

        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={data}
            margin={{
              top: 5,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis
              domain={[0, "dataMax + 50"]}
              tickFormatter={(tick) => `$${tick}`}
            />
            <Tooltip formatter={(value) => `$${value}`} />
            <Legend />
            <Bar
              dataKey="currentPrice"
              name="Current Price"
              fill="#8884d8"
              label={renderCustomizedLabel}
            />
            <Bar
              dataKey="projectedPrice"
              name="Projected Price"
              fill="#82ca9d"
              label={renderCustomizedLabel}
            />
          </BarChart>
        </ResponsiveContainer>

        <div className="pros-cons-section">
          <h5 className="section-title">Pros & Cons Analysis</h5>
          <Row className="pros-cons-row">
            <Col md={6} xs={12} className="pros-cons-col">
              <div className="pros">
                <h6 className="pros-title">
                  Pros <FaRegThumbsUp className="icon" />
                </h6>
                <ul className="pros-list">
                  <li>May significantly reduce drug prices.</li>
                  <li>
                    Could increase accessibility to medications for low-income
                    individuals.
                  </li>
                  <li>
                    May promote transparency and accountability in the
                    pharmaceutical industry.
                  </li>
                  <li>
                    Potential to stimulate competition and innovation in the
                    drug market.
                  </li>
                  <li>Could lead to overall better public health outcomes.</li>
                </ul>
              </div>
            </Col>
            <Col md={6} xs={12} className="pros-cons-col">
              <div className="cons">
                <h6 className="cons-title">
                  Cons <FaRegThumbsDown className="icon" />
                </h6>
                <ul className="cons-list">
                  <li>Potential backlash from pharmaceutical companies.</li>
                  <li>
                    Risk of reduced investment in research and development.
                  </li>
                  <li>
                    Possible issues related to quality control and safety with
                    imported drugs.
                  </li>
                  <li>Potential increase in taxpayer's burden.</li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </CardBody>

      <CardFooter />
    </CardComponent>
  );
};

export default VoteHealthCareVersion2;
