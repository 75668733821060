import React from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
import { FaVoteYea, FaSchool, FaStethoscope } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { AppPaths } from '../../AppPaths';

const ReferendumListPage: React.FC = () => {
  const iconTextStyle: React.CSSProperties = { marginLeft: '10px' };
  const cardStyle: React.CSSProperties = { display: 'flex', flexDirection: 'column', height: '100%' };
  const buttonContainerStyle: React.CSSProperties = { marginTop: 'auto', paddingTop: '10px', paddingBottom: '20px' };

  return (
    <Container>
      <h1 className="text-center mb-5">Ongoing Referendums</h1>
      <Row className="justify-content-center">
        <Col sm="6" lg="4" className="mb-4">
          <Card className="text-center" style={cardStyle}>
            <CardTitle className="card-header bg-primary text-white">
              <FaVoteYea />
              <span style={iconTextStyle}>Public Infrastructure Funding</span>
            </CardTitle>
            <CardBody>
              <p>
                This referendum aims to allocate funds exclusively for the maintenance and improvement of public infrastructure, such as roads, bridges, public transportation systems, and other public facilities. The proposed funding increase is intended to address the current infrastructure needs and ensure the long-term sustainability of essential public services.
              </p>
            </CardBody>
            <div style={buttonContainerStyle}>
              <Link to={AppPaths.TaxInsights} className="btn btn-primary">
                Learn More
              </Link>
            </div>
          </Card>
        </Col>
        <Col sm="6" lg="4" className="mb-4">
          <Card className="text-center" style={cardStyle}>
            <CardTitle className="card-header bg-success text-white">
              <FaSchool />
              <span style={iconTextStyle}>Public Education Funding</span>
            </CardTitle>
            <CardBody>
              <p>
                This referendum focuses on allocating additional funds to the education sector, with the goal of enhancing the quality of public education. The increased funding aims to support schools, educational programs, teacher salaries, and resources for students, ultimately contributing to improved educational outcomes and opportunities for all.
              </p>
            </CardBody>
            <div style={buttonContainerStyle}>
              <Link to={AppPaths.EducationInsights} className="btn btn-success">
                Learn More
              </Link>
            </div>
          </Card>
        </Col>
        <Col sm="6" lg="4" className="mb-4">
          <Card className="text-center" style={cardStyle}>
            <CardTitle className="card-header bg-danger text-white">
              <FaStethoscope />
              <span style={iconTextStyle}>Public Health Care Funding</span>
            </CardTitle>
            <CardBody>
              <p>
                This referendum is centered on implementing a reform in prescription drug policies. By incorporating changes such as drug price negotiations, importation of cost-effective drugs, and personal cost caps, the intention is to ensure accessible and affordable medication for everyone. The proposed policy aims to enhance public health outcomes and tackle disparities in drug accessibility and affordability.
              </p>
            </CardBody>
            <div style={buttonContainerStyle}>
              <Link to={AppPaths.HealthcareInsights} className="btn btn-danger">
                Learn More
              </Link>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ReferendumListPage;
