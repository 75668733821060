export interface ProsConsData {
  name: string;
  value: number;
  type: "Pro" | "Con";
}

export interface FinancialData {
  name: string;
  value: number;
}

export const mockProsConsData: ProsConsData[] = [
  { name: "Public Health", value: 80, type: "Pro" },
  { name: "Aesthetic Value", value: 70, type: "Pro" },
  { name: "Legal Recourse", value: 75, type: "Pro" },
  { name: "Clean-up Costs", value: -45, type: "Con" },
  { name: "Regulation Costs", value: -50, type: "Con" },
  { name: "Impact on Businesses", value: -60, type: "Con" },
];

export const adjustedImpactData = [
  {
    subject: "Public Health",
    Pro: 95,
    Con: 15,
    context:
      "Enhances community wellbeing by mitigating health risks associated with pollution.",
  },
  {
    subject: "Property Value",
    Pro: 90,
    Con: 10,
    context:
      "Prevents potential decline in property value due to environmental harm.",
  },
  {
    subject: "Environment",
    Pro: 85,
    Con: 20,
    context:
      "Encourages more responsible environmental behaviors and reduces pollution.",
  },
  {
    subject: "Legal Recourse",
    Pro: 80,
    Con: 10,
    context:
      "Provides property owners with the ability to seek justice if their property is adversely affected by pollution.",
  },
  {
    subject: "Aesthetic Value",
    Pro: 75,
    Con: 5,
    context: "Maintains the visual appeal of properties by reducing pollution.",
  },
  {
    subject: "Clean-up Costs",
    Pro: 15,
    Con: 80,
    context: "High costs associated with cleaning up pollution.",
  },
  {
    subject: "Regulation Costs",
    Pro: 20,
    Con: 75,
    context:
      "Significant expenses related to compliance with environmental regulations.",
  },
  {
    subject: "Impact on Businesses",
    Pro: 10,
    Con: 85,
    context:
      "Potential negative impact on business operations due to environmental compliance costs.",
  },
];

export const mockPositiveImpactData = [
  { subject: "Public Health", Pro: 95, Con: -15, Impact: 80 },
  { subject: "Property Value", Pro: 90, Con: -10, Impact: 80 },
  { subject: "Environment", Pro: 95, Con: -20, Impact: 75 },
  { subject: "Legal Recourse", Pro: 85, Con: -10, Impact: 75 },
  { subject: "Aesthetic Value", Pro: 80, Con: -5, Impact: 75 },
  { subject: "Clean-up Costs", Pro: 10, Con: -80, Impact: -70 },
  { subject: "Regulation Costs", Pro: 15, Con: -75, Impact: -60 },
  { subject: "Impact on Businesses", Pro: 5, Con: -90, Impact: -85 },
];

export const mockFinancialData: FinancialData[] = [
  { name: "Clean-up Costs", value: 45000000 }, // $45,000,000
  { name: "Regulation Costs", value: 30000000 }, // $30,000,000
  { name: "Potential Fines", value: 20000000 }, // $20,000,000
  { name: "Legal Fees", value: 5000000 }, // $5,000,000
];

export const COLORSEnvironment = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

export const mockFinancialDataV2 = [
  { name: "Compliance Costs", value: 35 },
  { name: "Fines and Penalties", value: 25 },
  { name: "Clean-Up Mandates", value: 20 },
  { name: "Legal Expenses", value: 20 },
];

export const mockPositiveImpactDataV2 = [
  { subject: "Public Health", Pro: 80, Con: -10, Impact: 70 },
  { subject: "Property Value", Pro: 60, Con: -20, Impact: 40 },
  { subject: "Environmental Accountability", Pro: 90, Con: -5, Impact: 85 },
  { subject: "Economic Impact", Pro: 50, Con: -50, Impact: 0 },
  { subject: "Implementation Challenges", Pro: 30, Con: -70, Impact: -40 },
];
