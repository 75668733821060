// data.js
export const budgetDataEducation = [
  { name: "Administrative", initialBudget: 690, newBudget: 676.2 },
  { name: "Education", initialBudget: 100, newBudget: 113.8 },
];

export const resourceDataEducation = [
  { name: "Textbooks & Workbooks", value: 20 },
  { name: "Digital Resources", value: 20 },
  { name: "Library Resources", value: 10 },
  { name: "Lab Equipment", value: 10 },
  { name: "Audio-Visual Materials", value: 10 },
  { name: "Interactive Learning Systems", value: 10 },
  { name: "Art Supplies & Music Instruments", value: 10 },
  { name: "Sports Equipment", value: 10 },
];

export const COLORSEducation = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#A3A3A3",
  "#FF00FF",
  "#808000",
  "#ADFF2F",
  "#FFD700",
  "#20B2AA",
];
