import {AppPaths} from "./AppPaths"
import {ContactUs} from "./components/ContactUs"
import EducationReferendumPage from "./components/referendum/EducationReferendumPage"
import {FutureOfDemocracy} from "./components/FutureOfDemocracy"
import HealthcareReferendumPage from "./components/referendum/HealthcareReferendumPage"
import {Home} from "./components/Home"
import {NewsAndUpdates} from "./components/NewsAndUpdates"
import {OurMission} from "./components/OurMission"
import ReferendumPage from "./components/referendum/ReferendumPage"
import TaxReferendumPage from "./components/referendum/TaxReferendumPage"
import VoteHistory from "./components/referendum/VoteHistory"
import EducationPage from "./components/education/EducationPage"
import TaxesPage from "./components/education/TaxesPage"
import ReferendumListPage from "./components/education/ReferendumListPage"
import HealthCarePage from "./components/education/HealthCarePage"
import EnvironmentReferendum from "./components/referendum/EnvironmentReferendum"
import HousingReferendum from "./components/referendum/HousingReferendum"
import WriterSupportReferendum from "./components/referendum/ForeignPolicyReferendum"
import VoteEnvironment from "./components/education/VoteEnvironment"
import VoteHousing from "./components/education/VoteHousing"
import VoteWriterSupport from "./components/education/VoteForeignPolicy"
import SupportPage from "./components/SupportPage"
import path from "path"
import VotePilotPage from "./components/VotePilotPage"
import PrivacyPolicyScreen from "./components/PrivacyPolicyScreen"
import AccountDeletionScreen from "./components/AccountDeletionScreen"
import UserAgreementScreen from "./components/TermsOfService"

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: AppPaths.ContactUs,
    element: <ContactUs />
  },
  {
    path: AppPaths.FutureOfDemocracy,
    element: <FutureOfDemocracy />
  },
  {
    path: AppPaths.OurMission,
    element: <OurMission />
  },
  {
    path: AppPaths.NewsAndUpdates,
    element: <NewsAndUpdates />
  },
  {
    path: AppPaths.ReferendumPage,
    element: <ReferendumPage />
  },
  {
    path: AppPaths.TaxReferendum,
    element: <TaxReferendumPage />
  },
  {
    path: AppPaths.EducationReferendum,
    element: <EducationReferendumPage />
  },
  {
    path: AppPaths.HealthcareReferendum,
    element: <HealthcareReferendumPage />
  },
  {
    path: AppPaths.VoteHistory,
    element: <VoteHistory />
  },
  {
    path: AppPaths.ReferendumListPage,
    element: <ReferendumListPage />
  },
  {
    path: AppPaths.TaxInsights,
    element: <TaxesPage />
  },
  {
    path: AppPaths.EducationInsights,
    element: <EducationPage />
  },
  {
    path: AppPaths.HealthcareInsights,
    element: <HealthCarePage />
  },
  {
    path: AppPaths.EnvironmentReferendum,
    element: <EnvironmentReferendum />
  },
  {
    path: AppPaths.HousingReferendum,
    element: <HousingReferendum />
  },
  {
    path: AppPaths.ForeignPolicyReferendum,
    element: <WriterSupportReferendum />
  },
  {
    path: AppPaths.EnvironmentInsights,
    element: <VoteEnvironment />
  },
  {
    path: AppPaths.HousingInsights,
    element: <VoteHousing />
  },
  {
    path: AppPaths.ForeignPolicyInsights,
    element: <VoteWriterSupport />
  },
  {
    path: AppPaths.Support,
    element: <SupportPage />
  },
  {
    path: AppPaths.VotePilot,
    element: <VotePilotPage />
  },
  {
    path: AppPaths.PrivacyPolicy,
    element: <PrivacyPolicyScreen />
  },
  {
    path: AppPaths.AccountDeletion,
    element: <AccountDeletionScreen />
  },
  {
    path: AppPaths.TermsOfService,
    element: <UserAgreementScreen />
  }
]

export default AppRoutes