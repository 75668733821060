import React from 'react'
import {Container, Row, Col} from 'reactstrap'

const AccountDeletionScreen: React.FC = () =>
{
    return (
        <Container style={styles.container}>
            <Row className="justify-content-center">
                <Col xs="12" md="8" lg="6">
                    <div style={styles.content}>
                        <h2 style={styles.sectionTitle}>Account Deletion Request</h2>
                        <p style={styles.paragraph}>
                            If you would like to delete your account and all associated data, please contact us using the email address
                            that was used to create your account.
                        </p>
                        <p style={styles.paragraph}>
                            Once we receive your request, we will delete all your account information and related data from our servers.
                        </p>
                        <p style={styles.paragraph}>
                            Please contact us at: <a href="mailto:contact@directdemocracy.global" style={styles.hyperlink}>contact@directdemocracy.global</a>
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

// CSS-in-JS styles for simple layout
const styles: {[key: string]: React.CSSProperties} = {
    container: {
        padding: '0rem 1rem',
        display: 'flex', // This makes sure the content is horizontally centered
        justifyContent: 'center',
    },
    content: {
        padding: '2rem',
        backgroundColor: '#fff',
        borderRadius: '8px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    },
    sectionTitle: {
        fontSize: '1.5rem',
        fontWeight: 700,
        marginBottom: '1rem',
        color: '#1C1C1E',
    },
    paragraph: {
        fontSize: '1.125rem',
        color: '#3A3A3C',
        lineHeight: '1.5',
        //marginTop: '1rem',
    },
    hyperlink: {
        color: '#007AFF',
        textDecoration: 'underline',
    },
}

export default AccountDeletionScreen