import React from 'react';
import EducationReferendum from './EducationReferendum';

const EducationReferendumPage = () => (
  <div>
    <EducationReferendum />
  </div>
);

export default EducationReferendumPage;
