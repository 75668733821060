import React, { FC, useState, useEffect } from 'react';
import { Container, Card } from 'reactstrap';
import { FaVoteYea, FaNetworkWired, FaLock, FaUserCheck, FaLightbulb } from 'react-icons/fa';

export const FutureOfDemocracy: FC = () => {
  const [inProp, setInProp] = useState(false);

  useEffect(() => {
    setInProp(true);
  }, []);

  const cardStyle = inProp
    ? {
        transition: 'opacity 500ms ease-in',
        opacity: 1,
      }
    : {
        opacity: 0.01,
      };

  return (
    <Container>
      <h2 className="mb-4">Tech-Driven Democracy: Redefining Participation</h2>
      <Card style={cardStyle} className="mb-4 p-3">
        <FaVoteYea size={32} className="mb-3" style={{ color: '#1f8a70' }} />
        <h3>The Primary Feature</h3>
        <p>
          The primary feature of this envisioned democratic platform is that it empowers voters to be a part of the decision-making process, moving beyond merely electing politicians.
          They would have the ability to regularly vote on key issues, making policy-making an ongoing, collaborative endeavor.
          This promises a broader engagement of citizens, fostering a deeper understanding and involvement in the democratic process.
        </p>
      </Card>
      <Card style={cardStyle} className="mb-4 p-3">
        <FaNetworkWired size={32} className="mb-3" style={{ color: '#334858' }} />
        <h3>The Technological Backbone</h3>
        <p>
          The technological backbone of this platform is peer-to-peer technology.
          This network design, unlike a centralized model, enables the platform to store and replicate votes across thousands of devices on the Internet.
          Decentralized storage models increase the resilience of the system as they lack a single point of failure.
          Consequently, the threat of vote elimination due to malicious cyber-attacks is significantly mitigated.
          This architectural choice instills greater security and trust in the democratic process, essential factors for successful civic participation.
        </p>
      </Card>
      <Card style={cardStyle} className="mb-4 p-3">
        <FaLock size={32} className="mb-3" style={{ color: '#95190c' }} />
        <h3>Ensuring Immutability of Votes</h3>
        <p>
          One of the paramount concerns in any voting system is the security and immutability of votes.
          The implementation of blockchain data storage mechanisms addresses this issue effectively.
          Blockchain technology ensures that once a vote is recorded in the database, all subsequent entries (votes) become dependent on it, thus eliminating the possibility of vote alteration post-recording.
          This enhances the integrity of the voting process, providing an immutable record of public opinion.
        </p>
      </Card>
      <Card style={cardStyle} className="mb-4 p-3">
        <FaUserCheck size={32} className="mb-3" style={{ color: '#614051' }} />
        <h3>Voter Authentication</h3>
        <p>
          Voter authentication is another critical aspect that this platform caters to through the use of public key cryptography.
          This technology allows the validation of a user’s device based on their public and private keys, a combination of a unique username, password, and additional personal information.
          This procedure strengthens the process of voter identification, reducing the risks of illegitimate voting and reinforcing the credibility of the electoral system.
        </p>
      </Card>
      <Card style={cardStyle} className="mb-4 p-3">
        <FaLightbulb size={32} className="mb-3" style={{ color: '#e6af2e' }} />
        <h3>In Conclusion</h3>
        <p>
          In conclusion, the confluence of peer-to-peer technology, blockchain and public key cryptography has the potential to revolutionize the democratic process.
          These technologies are fostering a new era of direct democracy, where citizens are not only voters but also active participants in policy-making.
          This software platform for direct democracy can thereby invigorate civic engagement, uphold the sanctity of votes and provide a secure and transparent platform for democratic participation.
          As we move forward, it is crucial to harness these technological capabilities to bolster democracy and build more inclusive societies.
        </p>
      </Card>
    </Container>
  );
};
