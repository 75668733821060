import React, {Component} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import AppRoutes from './AppRoutes'
import {Layout} from './components/Layout'
import './custom.css'
import {VoteContextProvider} from './components/referendum/VoteContext'
import {AppPaths} from './AppPaths'
import SupportPage from './components/SupportPage'
import PrivacyPolicyScreen from './components/PrivacyPolicyScreen'
import AccountDeletionScreen from './components/AccountDeletionScreen'
import UserAgreementScreen from './components/TermsOfService'

export default class App extends Component
{
  static displayName = App.name;

  render()
  {
    return (
      <VoteContextProvider>
        <Layout>
          <Routes>
            {AppRoutes.map((route, index) =>
            {
              const {element, ...rest} = route
              return <Route key={index} {...rest} element={element} />
            })}
            <Route path="*" element={<Navigate to="/" />} />
            <Route path={AppPaths.Support} element={<SupportPage />} />
            <Route path={AppPaths.PrivacyPolicy} element={<PrivacyPolicyScreen />} />
            <Route path={AppPaths.AccountDeletion} element={<AccountDeletionScreen />} />
            <Route path={AppPaths.TermsOfService} element={<UserAgreementScreen />} />
          </Routes>
        </Layout>
      </VoteContextProvider>
    )
  }
}
