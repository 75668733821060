import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  Card,
  CardHeader,
  CardBody,
  CardText,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  CardFooter,
} from "reactstrap";
import { FaRegThumbsDown, FaRegThumbsUp } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import Title from "./Title";
import { COLORS, data } from "./VoteForeignPolicyData";
import "./ProsCons.css";

const VoteForeignPolicyVersion2: React.FC = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  const CardComponent = isMobile ? "div" : Card;
  const CardHeaderComponent = isMobile ? "h2" : CardHeader;
  return (
    <div>
      <CardComponent>
        <Title value={"Foreign Policy Referendum"} />
        <CardBody>
          <h5>The Proposal</h5>
          <p>
            This referendum poses a critical question about our nation's foreign
            policy: Should we initiate a targeted military operation in the
            neighboring country X, to mitigate perceived threats to our national
            security?
          </p>

          <p>
            A critical factor in making an informed decision is understanding
            the potential costs associated with the operation. The chart below
            breaks down the expected costs (in roubles) associated with the
            proposed military action.
          </p>

          <ResponsiveContainer width="100%" height={300}>
            <PieChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
              <Pie
                dataKey="cost"
                isAnimationActive={false}
                data={data}
                cx="50%"
                cy="50%"
                outerRadius={100}
                fill="#8884d8"
                label={
                  isMobile
                    ? ({ name, cost }) => `${cost}B₽`
                    : ({ name, cost }) => `${name}: ${cost}B₽`
                }
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>

              <Tooltip formatter={(value: number) => `${value}B₽`} />
              <Legend verticalAlign="bottom" height={36} />
            </PieChart>
          </ResponsiveContainer>

          <div className="pros-cons-section" style={{ marginTop: 40 }}>
            <h5 className="section-title">Pros & Cons Analysis</h5>
            <Row className="pros-cons-row">
              <Col md={6} xs={12} className="pros-cons-col">
                <div className="pros">
                  <h6 className="pros-title">
                    Pros <FaRegThumbsUp className="icon" />
                  </h6>
                  <ul className="pros-list">
                    <li>
                      Enhanced National Security: If successful, the operation
                      would neutralize immediate threats to our safety.
                    </li>
                    <li>
                      Deterrence: A strong show of force may deter future
                      potential threats from Country X or others.
                    </li>
                    <li>
                      Protection of Interests: This operation would secure and
                      protect our national interests.
                    </li>
                    <li>
                      Preventative Measures: By taking action now, we might
                      avoid larger conflicts in the future.
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={6} xs={12} className="pros-cons-col">
                <div className="cons">
                  <h6 className="cons-title">
                    Cons <FaRegThumbsDown className="icon" />
                  </h6>
                  <ul className="cons-list">
                    <li>
                      International Backlash: The operation may harm our
                      diplomatic relations and standing in the global community.
                    </li>
                    <li>
                      Possible Sanctions: Breaching international laws may
                      result in economic and political sanctions.
                    </li>
                    <li>
                      Escalation of Conflict: Military action could potentially
                      escalate the situation, leading to a full-scale war.
                    </li>
                    <li>
                      Human and Financial Cost: The operation will likely result
                      in loss of life and significant financial expenditure.
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </CardBody>
        <CardFooter />
      </CardComponent>
    </div>
  );
};

export default VoteForeignPolicyVersion2;
