import React, { useState } from "react";
import {
  Bar,
  XAxis,
  YAxis,
  Tooltip as BarTooltip,
  CartesianGrid,
  Legend,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Line,
  ComposedChart,
  RadarChart,
  Radar,
  PolarRadiusAxis,
  PolarGrid,
  PolarAngleAxis,
} from "recharts";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  CardFooter,
  Button,
  Container,
  TooltipProps,
} from "reactstrap";
import { FaRegThumbsDown, FaRegThumbsUp } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import Title from "./Title";
import {
  adjustedImpactData,
  COLORSEnvironment,
  FinancialData,
  mockFinancialData,
  mockPositiveImpactData,
} from "./VoteEnvironmentData";
import "./ProsCons.css";

interface CustomTooltipProps {
  active?: boolean;
  payload?: any[];
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#f9f9f9",
          padding: "12px",
          borderRadius: "8px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          border: "1px solid #ddd",
          maxWidth: "200px",
        }}
      >
        <p
          className="label"
          style={{ fontWeight: "bold", marginBottom: "6px" }}
        >
          {data.subject}
        </p>
        <p
          className="intro"
          style={{ margin: "4px 0" }}
        >{`Pro: ${data.Pro}`}</p>
        <p
          className="intro"
          style={{ margin: "4px 0" }}
        >{`Con: ${data.Con}`}</p>
        <p className="desc" style={{ marginTop: "8px", color: "#666" }}>
          {data.context}
        </p>
      </div>
    );
  }

  return null;
};

const VoteEnvironment: React.FC = () => {
  const renderLabel = (entry: FinancialData) =>
    `${entry.name}: ${entry.value}%`;
  const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  const CardComponent = isMobile ? "div" : Card;
  const CardHeaderComponent = isMobile ? "h2" : CardHeader;

  const [showChart, setShowChart] = useState(false);

  const impactOverview = () =>
    isMobile ? (
      showChart ? (
        <div
          style={{
            width: "100%",
            overflowX: "scroll",
            overflowY: "hidden",
          }}
        >
          <style>
            {`
      ::-webkit-scrollbar {
        width: 0.4rem;
        height: 0.4rem;
      }
      ::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 0.2rem;
      }
      `}
          </style>
          <ComposedChart
            width={1400}
            height={300}
            data={mockPositiveImpactData}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis
              dataKey="subject"
              interval={0}
              tick={{ fontSize: 12 }}
              angle={-30}
              textAnchor="end"
              height={80}
            />
            <YAxis domain={[-100, 100]} />
            <Bar dataKey="Pro" barSize={30} fill="#413ea0" />
            <Bar dataKey="Con" barSize={30} fill="#c42c2c" />
            <Line type="monotone" dataKey="Impact" stroke="#ff7300" />
            <Tooltip />
            <Legend
              layout="horizontal"
              verticalAlign="bottom"
              align={isMobile ? "left" : "center"}
            />
          </ComposedChart>
        </div>
      ) : (
        <Button
          color="primary"
          className="custom-button"
          style={{
            marginBottom: "10px",
            padding: "0.25rem 0.5rem",
            fontSize: "0.875rem",
          }}
          onClick={() => setShowChart(true)}
        >
          View Chart
        </Button>
      )
    ) : (
      <div
        style={{
          height: "50vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "-30px", // Reduced margin from the top
          marginBottom: "-20px", // Reduced margin from the bottom
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <RadarChart
            cx="50%"
            cy="50%"
            outerRadius="80%"
            data={adjustedImpactData}
          >
            <PolarGrid />
            <PolarAngleAxis dataKey="subject" />
            <PolarRadiusAxis angle={30} domain={[0, 100]} />
            <Radar
              name="Pro (Benefit)"
              dataKey="Pro"
              stroke="#28a745"
              fill="#28a745"
              fillOpacity={0.6}
            />
            <Radar
              name="Con (Drawback)"
              dataKey="Con"
              stroke="#6c757d"
              fill="#6c757d"
              fillOpacity={0.6}
            />
            <Legend />
            <Tooltip content={<CustomTooltip />} />
          </RadarChart>
        </ResponsiveContainer>
      </div>
    );

  const renderProsCons = () => (
    <div className="pros-cons-section">
      <h5 className="section-title">Pros and Cons Analysis</h5>
      <Row className="pros-cons-row">
        <Col md={6} xs={12} className="pros-cons-col">
          <div className="pros">
            <h6 className="pros-title">
              Pros <FaRegThumbsUp className="icon" />
            </h6>
            <ul className="pros-list">
              <li>
                Public Health Protection: Enhances community wellbeing by
                mitigating health risks associated with pollution.
              </li>
              <li>
                Property Value Preservation: Prevents potential decline in
                property value due to environmental harm.
              </li>
              <li>
                Environmental Accountability: Introduces stringent regulations
                and penalties for those who pollute, encouraging more
                responsible behaviors.
              </li>
              <li>
                Legal Recourse: Provides property owners with the ability to
                seek justice if their property is adversely affected by
                pollution.
              </li>
            </ul>
          </div>
        </Col>
        <Col md={6} xs={12} className="pros-cons-col">
          <div className="cons">
            <h6 className="cons-title">
              Cons <FaRegThumbsDown className="icon" />
            </h6>
            <ul className="cons-list">
              <li>
                Regulatory Burden: Could lead to an increased bureaucracy due to
                the need for more regulatory oversight.
              </li>
              <li>
                Economic Impact: May negatively affect businesses, especially
                small enterprises, due to the cost of compliance or penalties.
              </li>
              <li>
                Implementation Challenges: Difficulties in enforcement and
                proving indirect pollution could occur.
              </li>
              <li>
                Potential for Abuse: There might be a risk of frivolous lawsuits
                or misuse of the act to settle personal disputes.
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </div>
  );

  return (
    <CardComponent>
      <Title value={"Environment Referendum"} />
      <CardBody>
        <h5>The Proposal</h5>
        <section className="mb-4">
          <p className="text-sm">
            The referendum proposes the Private Property Environmental
            Protection Act to prohibit pollution on private property, giving
            owners legal recourse for damages. It aims to safeguard public
            health, preserve property aesthetics, and promote an environmentally
            conscious society. The act includes strict regulations, penalties,
            and mandatory clean-up mandates. Its goal is to balance property
            rights with the importance of a clean environment.
          </p>
        </section>

        <section className="mb-4">
          <h6 className="font-weight-bold mb-2">Voting Information</h6>
          <p className="text-sm mb-2">
            <strong>A "YES" vote</strong> supports the implementation of the
            Private Property Environmental Protection Act, aiming to prohibit
            pollution on private property and provide legal recourse for
            damages.
          </p>
          <p className="text-sm">
            <strong>A "NO" vote</strong> opposes the implementation of the act,
            maintaining the current state of environmental regulations on
            private property.
          </p>
        </section>

        <section className="mb-4">
          <h5 className="font-weight-bold mb-2">Impact Overview</h5>
          {isMobile ? (
            <p className="text-sm">
              The chart below shows both immediate and long-term impacts of the
              proposed act across various sectors. The bars reflect the direct
              consequences, with positive values signaling benefits and
              negatives indicating challenges. The line graph overlays these to
              predict trends over time, providing a nuanced forecast of the
              potential implications of the legislation.
            </p>
          ) : (
            <p className="text-sm">
              The chart below shows both immediate and long-term impacts of the
              proposed act across various sectors. The green areas represent the
              benefits (pros), while the grey areas indicate the challenges
              (cons). Hover over each segment to see detailed descriptions of
              the impacts and drawbacks associated with each sector.
            </p>
          )}
          {impactOverview()}
        </section>

        <h5 className="font-weight-bold mb-2">
          Financial Implications Analysis
        </h5>
        <p className="text-sm">
          Businesses may incur higher costs from clean-up mandates, fines,
          compliance, and legal fees, impacting profitability. Proving indirect
          pollution and quantifying harm may burden the judicial system. This
          act could drive businesses to relocate to areas with less stringent
          environmental regulations. The pie chart below shows the estimated
          financial cost distribution if the act is passed.
        </p>

        <div style={{ width: "100%", height: 350 }}>
          <ResponsiveContainer>
            <PieChart>
              <Pie
                data={mockFinancialData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={100}
                fill="#8884d8"
                label={
                  isMobile
                    ? ({ name, value }) => `$${(value / 1000000).toFixed(1)}M`
                    : ({ name, value }) =>
                        renderLabel({
                          name,
                          value: parseFloat((value / 1000000).toFixed(1)),
                        })
                }
              >
                {mockFinancialData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORSEnvironment[index % COLORSEnvironment.length]}
                  />
                ))}
              </Pie>
              <BarTooltip
                formatter={(value) =>
                  `$${(Number(value) / 1000000).toFixed(1)}M`
                }
              />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>

        {renderProsCons()}
      </CardBody>

      <CardFooter />
    </CardComponent>
  );
};

export default VoteEnvironment;
