import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import VoteTaxes from './VoteTaxes';
import VoteTaxesVersion2 from './VoteTaxesVersion2';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

interface DataItem {
  name: string;
  value: number;
}

const TaxesPage: React.FC = () => {
  const totalData: DataItem[] = [
    { name: 'Roads & Bridges', value: 200 },
    { name: 'Public Buildings', value: 100 },
    { name: 'Parks & Recreation', value: 100 },
    { name: 'Public Utilities', value: 100 },
  ];

  const adjustValue = (value: number) => {
    const adjustment = Math.random() * 0.1 - 0.05; // Randomly adjust value by up to ±5%
    return value + value * adjustment;
  }
  
  const yearlyData = Array(10).fill(null).map((_, i) => {
    return {
      name: `${2024 + i + 1}`,
      data: totalData.map(item => {
        const yearlyValue = item.value / 10; // Base yearly value
        const adjustedValue = adjustValue(yearlyValue); // Adjusted yearly value
        return { name: item.name, value: adjustedValue };
      }),
    };
  });

  // Function to get the current version from the URL
  const getCurrentVersionFromURL = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('before') ? 1 : 0;
  };

  const [currentVersion, setCurrentVersion] = useState(getCurrentVersionFromURL());

  useEffect(() => {
    // Update currentVersion state when the component mounts
    setCurrentVersion(getCurrentVersionFromURL());
  }, []);

  useEffect(() => {
    // Update the URL based on the current version
    const url = new URL(window.location.href);
    if (currentVersion === 1) {
      url.searchParams.set('before', 'true');
    } else {
      url.searchParams.delete('before');
    }
    window.history.replaceState({}, '', url.toString());
  }, [currentVersion]);

  const handlePrevious = () => {
    setCurrentVersion((prevVersion) => (prevVersion > 0 ? prevVersion - 1 : 1)); // Assuming there are 2 versions (0, 1)
  };

  const handleNext = () => {
    setCurrentVersion((prevVersion) => (prevVersion < 1 ? prevVersion + 1 : 0)); // Assuming there are 2 versions (0, 1)
  };

  const renderVersionContent = () => {
    switch (currentVersion) {
      case 0:
        return (
          <VoteTaxes
            title="Public Infrastructure Funding"
            description="This upcoming referendum asks whether we should increase funds for public taxes. The proposed increase is significant and could have a substantial impact on our society. Here's what the new funding distribution would look like:"
            yearlyData={yearlyData} 
            totalData={totalData}
          />
        );
      case 1:
        return <VoteTaxesVersion2 title="Public Infrastructure Funding" description="This upcoming referendum asks whether we should increase funds for public taxes. The proposed increase is significant and could have a substantial impact on our society. Here's what the new funding distribution would look like:" yearlyData={yearlyData} totalData={totalData} />;
      default:
        return null;
    }
  };

  return (
    <div>
      {renderVersionContent()}
      {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
        <button onClick={handlePrevious} className="custom-button">
          <FaArrowLeft className="custom-icon" /> Previous
        </button>
        <button onClick={handleNext} className="custom-button">
          Next <FaArrowRight className="custom-icon" />
        </button>
      </div> */}
    </div>
  );
};

export default TaxesPage;