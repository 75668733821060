// src/components/EducationReferendum.tsx

import React, {FC, CSSProperties, useEffect} from 'react'
import {useMediaQuery} from 'react-responsive'
import {useLocation} from 'react-router-dom'
import ReferendumForm from './ReferendumForm'

const EducationReferendum: FC = () =>
{
  const isMobile = useMediaQuery({query: '(max-width: 768px)'})
  const location = useLocation()

  useEffect(() =>
  {
    window.scrollTo(0, 0)
  }, [location])

  // Styles
  const styles: {[key: string]: CSSProperties} = {
    container: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: isMobile ? '17.5px 14px' : '25px 20px',
      fontFamily: 'Helvetica Neue, sans-serif',
      color: '#000',
    },
    title: {
      fontSize: isMobile ? '28px' : '36px',
      fontWeight: 'bold',
      marginBottom: isMobile ? '14px' : '20px',
      textAlign: 'center',
    },
    text: {
      fontSize: isMobile ? '16px' : '18px',
      lineHeight: 1.6,
      textAlign: 'left',
    },
    sectionTitle: {
      fontSize: isMobile ? '24px' : '28px',
      fontWeight: 'bold',
      marginBottom: isMobile ? '14px' : '20px',
      textAlign: 'left',
    },
    list: {
      paddingLeft: '20px',
      marginBottom: isMobile ? '14px' : '20px',
    },
    listItem: {
      marginBottom: isMobile ? '10px' : '12px',
    },
    subList: {
      paddingLeft: '20px',
    },
  }

  const voteDescription = (
    <div>
      <h1 style={styles.title}>
        Proposition 138: Enhance Educational Resources and Increase Teacher Salaries
      </h1>
      <p style={styles.text}>
        A proposal has been made to reallocate 2% of the administrative budget,
        approximately $13.8 billion, towards enhancing educational resources and
        increasing teacher salaries. This would result in an estimated increase of
        $143 per teacher salary and an equivalent increase in the per student budget
        for educational resources.
      </p>
      <h2 style={styles.sectionTitle}>Proposal:</h2>
      <ul style={styles.list}>
        <li style={styles.listItem}>
          <strong>Enactment of a Reallocation:</strong> The 2% reallocation of the
          administrative budget will take effect on January 1, 2025.
        </li>
        <li style={styles.listItem}>
          <strong>Dedication of Revenues:</strong> All additional revenues generated
          by the reallocation will be expressly earmarked for the enhancement of
          educational resources and the increase of teacher salaries.
        </li>
        <li style={styles.listItem}>
          <strong>Use of Funds:</strong> Funds collected from the reallocation will
          be used to:
          <ul style={styles.subList}>
            <li style={styles.listItem}>Increase teacher salaries by $143 per teacher.</li>
            <li style={styles.listItem}>
              Increase the per student budget for educational resources by an equivalent amount.
            </li>
            <li style={styles.listItem}>
              Support other projects that enhance educational resources and increase teacher salaries.
            </li>
          </ul>
        </li>
        <li style={styles.listItem}>
          <strong>Oversight and Transparency:</strong> An annual audit will be
          conducted to ensure that all revenues are spent as intended, and a report
          on the use of funds will be made publicly available.
        </li>
      </ul>
      <h2 style={styles.sectionTitle}>Voting:</h2>
      <p style={styles.text}>
        <strong>A "YES" vote</strong> approves the reallocation of 2% of the
        administrative budget to equally increase teacher salaries and enhance
        educational resources per student.
      </p>
      <p style={styles.text}>
        <strong>A "NO" vote</strong> opposes the reallocation of 2% of the
        administrative budget, maintaining the current allocation.
      </p>
    </div>
  )

  return (
    <div style={styles.container}>
      <ReferendumForm
        referendum="Education Referendum"
        voteDescription={voteDescription}
      />
    </div>
  )
}

export default EducationReferendum
