import React from 'react';
import ReferendumForm from './ReferendumForm';
import VoteDescription from './VoteDescription';
import { Card, CardBody, CardFooter, CardHeader, CardText } from 'reactstrap';

const HousingReferendum = () => {
    const paragraphs: string[] = [
        "The State hereby proposes the Shelter or Service Referendum to address the critical issue of homelessness affecting our communities. This proposition, if passed, will initiate the implementation of a law offering two options to homeless individuals: relocation to designated, government-funded communities equipped with essential resources for living, employment assistance, and rehabilitation programs, or participate in community improvement projects if they refuse this option. The aim of this referendum is to provide structured support to homeless individuals while encouraging community participation. Your vote will contribute significantly to shaping our policies and the future course of our communities. Do you approve or reject this proposal?",
    ];


    return (<div>
        <ReferendumForm
            referendum="Housing Referendum"
            voteDescription={
                <Card className="mb-3">
                    <CardHeader tag="h5">Proposition 149: Shelter or Service</CardHeader>
                    <CardBody>
                        <div>
                            This proposition seeks to address the growing homelessness issue in the state. If passed, it would enact the following laws:
                            <ol>
                                <li>
                                    Homeless individuals will be offered relocation to designated, government-funded communities.
                                    These communities will provide essential living resources, employment assistance, and rehabilitation programs.
                                </li>
                                <li>
                                    If a homeless individual refuses this offer, they will be invited to participate in community improvement projects.
                                </li>
                            </ol>
                            This proposal aims to provide a structured approach to homelessness that focuses on assistance and rehabilitation,
                            while encouraging community participation and service.
                        </div>
                        <h6 style={{ marginTop: 15 }}>Voting:</h6>
                        <p>
                            <strong>A "YES" vote</strong> means you support enacting laws to relocate homeless individuals to government-funded communities and offering community service opportunities for those who decline.
                        </p>
                        <p>
                            <strong>A "NO" vote</strong> means you do not support these proposed measures.
                        </p>
                    </CardBody>
                    <CardFooter />
                </Card>
            }
        /></div>)
};

export default HousingReferendum;
