import React from 'react';
import { FaRegThumbsDown, FaRegThumbsUp } from 'react-icons/fa';
import { Card, CardBody, CardFooter, CardHeader, Col, Row, Tooltip as ReactTooltip, Progress } from 'reactstrap';
import { ResponsiveContainer, Tooltip, PieChart, Pie, Cell, LineChart, Line, XAxis, YAxis, CartesianGrid, Legend } from 'recharts';
import { useMediaQuery } from 'react-responsive';
import Title from './Title';

interface DataItem {
  name: string;
  value: number;
}

interface YearlyDataItem {
  name: string;
  data: DataItem[];
}

interface VoteTaxesProps {
  title: string;
  description: string;
  totalData: DataItem[];
  yearlyData: YearlyDataItem[];
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const VoteTaxesVersion2: React.FC<VoteTaxesProps> = ({ title, description, totalData, yearlyData }) => {
  const totalValue = totalData.reduce((total, item) => total + item.value, 0);
  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' });
  let year = 2023;
  const style = isMobile ? { fontSize: '0.8rem' } : {};
  const CardComponent = isMobile ? 'div' : Card;

  return (
    <CardComponent>
    <Title value={title} />
   <CardBody>
     <h5>The Proposal</h5>
     <p>
       The referendum proposes a 0.5% increase in the local sales tax rate for the next 10 years. This increase means that for every $100 spent, an
       additional 50 cents will be dedicated to improving and maintaining public infrastructure.
     </p>
     <h5>Total Revenue Breakdown</h5>
     <p>
       Over the next 10 years, the proposed tax increase is estimated to generate an additional $500 million. This section provides a breakdown of how that revenue is projected to be used.
     </p>
     <div style={{ width: '100%', height: '300px' }}>
       <ResponsiveContainer>
         <PieChart>
           <Pie
             dataKey="value"
             isAnimationActive={false}
             data={totalData}
             cx="50%"
             cy="50%"
             outerRadius={80}
             fill="#8884d8"
             label={isMobile ? ({ name, value }) => `$${value}M` : ({ name, value }) => `${name}: $${value} million`}
           >
             {totalData.map((entry, index) => (
               <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
             ))}
           </Pie>
           <Tooltip formatter={(value) => [`$${value} million`]} />
           <Legend />
         </PieChart>
       </ResponsiveContainer>
     </div>
     <h5 style={{ marginTop: 10 }}>Pros & Cons of Local Sales Tax Increase</h5>
     <Row style={{ marginTop: 10 }}>
       <Col>
         <div >
           <h6>Pros <FaRegThumbsUp style={{ color: 'green' }} /></h6>
           <ul>
             <li>Improved Infrastructure: Better maintenance and enhancement of public infrastructure.</li>
             <li>Job Creation: Potential for increased employment opportunities in infrastructure projects.</li>
             <li>Long-Term Economic Growth: Enhanced infrastructure could attract businesses, leading to economic growth.</li>
             <li>Community Safety: Improved road, bridge, and public building conditions lead to safer communities.</li>
           </ul>
         </div>
       </Col>
     </Row>
     <Row>
       <Col>
         <div >
           <h6>Cons <FaRegThumbsDown style={{ color: 'red' }} /></h6>
           <ul>
             <li>Increased Cost of Living: Higher sales tax can increase overall expenses for residents, disproportionately affecting low-income households.</li>
             <li>Potential Misallocation: Risk of funds not being used as intended due to administrative or governance issues.</li>
             <li>Negative Impact on Local Businesses: Higher sales tax might deter consumers, impacting local businesses' sales.</li>
             <li>Dependency on Consumption: Sales tax revenue depends on consumer spending, which can fluctuate based on economic conditions.</li>
           </ul>
         </div>
       </Col>
     </Row>
     <h5>Yearly Revenue Breakdown</h5>
     <p>
       The distribution of funds will vary slightly from year to year to accommodate changing priorities and needs. Here is a breakdown of the projected revenue use for each year.
     </p>
     {yearlyData.map((yearData, index) => {
       const totalValue = yearData.data.reduce((total, item) => total + item.value, 0);
       return (
         <Card key={`year-${index}`} className="mt-4" >
           <CardHeader>{++year}</CardHeader>
           <CardBody>
             <div>
               <div style={{ width: '100%', height: '300px' }}>
                 <ResponsiveContainer>
                   <PieChart>
                     <Pie
                       style={style}
                       dataKey="value"
                       isAnimationActive={false}
                       data={yearData.data}
                       cx="50%"
                       cy="50%"
                       outerRadius={80}
                       fill="#8884d8"
                       label={isMobile ? ({ name, value }) => `$${(value).toFixed(1)}M` : ({ name, value }) => `$${(value).toFixed(1)} million`}
                     >
                       {yearData.data.map((entry, index) => (
                         <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                       ))}
                     </Pie>
                     <Tooltip formatter={(value) => [`$${(value as number).toFixed(1)} million`]} />
                     <Legend />
                   </PieChart>
                 </ResponsiveContainer>
               </div>
               <div className="mt-4">
                 {yearData.data.map((item, index) => (
                   <div key={`progress-${index}`} className="mb-3">
                     <span className="font-weight-bold">{item.name}</span>
                     <Progress width="80%" value={item.value} max={totalValue} color={COLORS[index % COLORS.length]} className="my-2" />
                     <small>{`${((item.value / totalValue) * 100).toFixed(2)}% of the yearly revenue is proposed to be spent on ${item.name}`}</small>
                   </div>
                 ))}
               </div>
             </div>
           </CardBody>
           <CardFooter />
         </Card>
       );
     })}

   </CardBody>
   <CardFooter />
 </CardComponent>
);
};

export default VoteTaxesVersion2;