// src/components/VoteTaxes.tsx

import React, {FC, CSSProperties, useEffect} from 'react'
import {useMediaQuery} from 'react-responsive'
import {useLocation} from 'react-router-dom'
import
{
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  Line,
  Legend,
} from 'recharts'
import {FaRegThumbsDown, FaRegThumbsUp} from 'react-icons/fa'
import Title from './Title'

interface DataItem
{
  name: string
  value: number
}

interface YearlyDataItem
{
  name: string
  data: DataItem[]
}

interface VoteTaxesProps
{
  title: string
  description: string
  totalData: DataItem[]
  yearlyData: YearlyDataItem[]
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']

const VoteTaxes: FC<VoteTaxesProps> = ({
  title,
  description,
  totalData,
  yearlyData,
}) =>
{
  const isMobile = useMediaQuery({query: '(max-width: 768px)'})
  const location = useLocation()

  useEffect(() =>
  {
    window.scrollTo(0, 0)
  }, [location])

  // Styles
  const styles: {[key: string]: CSSProperties} = {
    container: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: isMobile ? '17.5px 14px' : '25px 20px',
      fontFamily: 'Helvetica Neue, sans-serif',
      color: '#000',
    },
    title: {
      fontSize: isMobile ? '28px' : '36px',
      fontWeight: 'bold',
      marginBottom: isMobile ? '14px' : '20px',
      textAlign: 'center',
    },
    sectionTitle: {
      fontSize: isMobile ? '24px' : '28px',
      fontWeight: 'bold',
      marginBottom: isMobile ? '14px' : '20px',
    },
    text: {
      fontSize: isMobile ? '16px' : '18px',
      lineHeight: 1.6,
      marginBottom: isMobile ? '14px' : '20px',
    },
    chartContainer: {
      marginBottom: isMobile ? '14px' : '20px',
    },
    prosConsContainer: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      gap: '20px',
      marginBottom: isMobile ? '14px' : '20px',
    },
    prosConsColumn: {
      flex: 1,
    },
    prosConsTitle: {
      fontSize: isMobile ? '20px' : '24px',
      fontWeight: 'bold',
      marginBottom: isMobile ? '10px' : '12px',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
    list: {
      listStyleType: 'disc',
      paddingLeft: '20px',
    },
  }

  const impactOverview = !isMobile && (
    <div>
      <h2 style={styles.sectionTitle}>Impact Overview</h2>
      <div style={styles.chartContainer}>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            data={yearlyData.map((yearData) => ({
              name: yearData.name,
              ...yearData.data.reduce(
                (acc, item) => ({...acc, [item.name]: item.value}),
                {}
              ),
            }))}
            margin={{top: 10, right: 30, left: 10, bottom: 10}}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} stroke="#ccc" />
            <XAxis
              dataKey="name"
              tick={{fontSize: 12}}
              label={{
                value: 'Year',
                position: 'insideBottomRight',
                offset: -5,
                fontSize: 14,
                fontWeight: 'bold',
              }}
            />
            <YAxis
              tickFormatter={(value) =>
                typeof value === 'number' ? value.toFixed(1) : value
              }
              tick={{fontSize: 12}}
              label={{
                value: 'Value in Millions',
                angle: -90,
                position: 'insideLeft',
                fontSize: 14,
                fontWeight: 'bold',
              }}
            />
            <Tooltip
              formatter={(value) =>
                typeof value === 'number'
                  ? `$${value.toFixed(1)} million`
                  : value
              }
              contentStyle={{
                fontSize: 12,
                backgroundColor: '#f5f5f5',
                borderColor: '#ccc',
              }}
              itemStyle={{color: '#333'}}
            />
            <Legend wrapperStyle={{fontSize: 14, fontWeight: 'bold'}} />
            {yearlyData[0].data.map((item, index) => (
              <Line
                key={index}
                type="monotone"
                dataKey={item.name}
                stroke={COLORS[index % COLORS.length]}
                strokeWidth={2}
                dot={{r: 4, strokeWidth: 2, fill: '#fff'}}
                activeDot={{r: 6}}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  )

  const renderProsCons = () => (
    <div style={styles.prosConsContainer}>
      <div style={styles.prosConsColumn}>
        <h3 style={styles.prosConsTitle}>
          Pros <FaRegThumbsUp />
        </h3>
        <ul style={styles.list}>
          <li>
            Improved Infrastructure: Better maintenance and enhancement of public infrastructure.
          </li>
          <li>
            Job Creation: Increased employment opportunities in infrastructure projects.
          </li>
          <li>
            Long-Term Economic Growth: Enhanced infrastructure could attract businesses, leading to economic growth.
          </li>
          <li>
            Community Safety: Improved road, bridge, and public building conditions lead to safer communities.
          </li>
        </ul>
      </div>
      <div style={styles.prosConsColumn}>
        <h3 style={styles.prosConsTitle}>
          Cons <FaRegThumbsDown />
        </h3>
        <ul style={styles.list}>
          <li>
            Increased Cost of Living: Higher sales tax can increase overall expenses for residents, disproportionately affecting low-income households.
          </li>
          <li>
            Potential Misallocation: Risk of funds not being used as intended due to administrative or governance issues.
          </li>
          <li>
            Negative Impact on Local Businesses: Higher sales tax might deter consumers, impacting local businesses' sales.
          </li>
          <li>
            Dependency on Consumption: Sales tax revenue depends on consumer spending, which can fluctuate based on economic conditions.
          </li>
        </ul>
      </div>
    </div>
  )

  return (
    <div style={styles.container}>
      {/* <Title value={title} /> */}
      <h1 style={styles.title}>The Proposal</h1>

      <p style={styles.text}>
        This proposition, if approved, will increase the local sales tax rate by 0.5% for the next decade. The additional tax revenue, estimated at $50 million per year, will be specifically allocated towards the maintenance and improvement of public infrastructure in our community.
      </p>

      <h2 style={styles.sectionTitle}>Proposal Details</h2>
      <ul style={styles.list}>
        <li>
          <strong>Sales Tax Increase:</strong> The local sales tax rate will be increased by 0.5% for a period of ten years, starting from January 1, 2025, and ending on December 31, 2034.
        </li>
        <li>
          <strong>Use of Funds:</strong> Funds will be used for projects such as repairing roads and bridges, upgrading public transportation, improving parks and public spaces, and enhancing utilities like water and sewage systems.
        </li>
        <li>
          <strong>Oversight:</strong> An annual audit will ensure funds are spent as intended, with a public report available.
        </li>
      </ul>

      <h2 style={styles.sectionTitle}>Voting Information</h2>
      <p style={styles.text}>
        <strong>A "YES" vote</strong> approves the sales tax increase of 0.5% for ten years, dedicated to public infrastructure maintenance and improvement.
      </p>
      <p style={styles.text}>
        <strong>A "NO" vote</strong> opposes the sales tax increase, maintaining the current sales tax rate.
      </p>

      {impactOverview}

      {renderProsCons()}
    </div>
  )
}

export default VoteTaxes
