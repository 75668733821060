// Breadcrumb.tsx
import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

interface BreadcrumbProps {
  items: {path: string, label: string}[];
}

const BreadcrumbNav: React.FC<BreadcrumbProps> = ({ items }) => {
  return (
    <Breadcrumb style={{ marginBottom: -15,  }}>
      {items.map((item, index) => (
        <BreadcrumbItem key={index} active={index === items.length - 1}>
          <Link to={item.path} style={{
                textDecoration: index === items.length - 1 ? 'none' : 'underline',
                color: index === items.length - 1 ? '#212529' : '#00416a',
                fontWeight: index === items.length - 1 ? 'bold' : 'normal'
              }}>
            {item.label}
          </Link>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};

export default BreadcrumbNav;
