import { NewsItem } from "../components/NewsAndUpdates";

export const newsData: NewsItem[] = [
    {
        title: "Designing Democracy: The Birth of our Direct Democracy Platform Prototype",
        content: (
            <div>
                <p>
                    Currently, we are in the developmental stages of creating a prototype for our Direct Democracy platform.
                    As of now, we've integrated six referendums into the system, all paired with comprehensive educational sections.
                    The intent behind these sections is to provide voters with a thorough understanding of the referendums,
                    thereby empowering them to make well-informed decisions. We're optimistic that this prototype,
                    marking our initial step in the quest to elevate the democratic process, will be ready by the close of July.
                </p>
            </div>
        ),
        date: "2023-07-06"
    },
    {
        title: "The Essence of Direct Democracy",
        content: (
            <div>
                <p>Direct democracy embodies the ideal that every citizen has a direct and equal influence in the decisions of the state. As an ideal, it forms a key part of our conception of a truly egalitarian society.</p>
                <p>However, implementing direct democracy in its purest form can be challenging, especially in large, complex societies. Balancing the ideals of direct participation with practical considerations is a key philosophical and political challenge.</p>
            </div>
        ),
        date: "2023-05-28"
    },
    {
        title: "Direct Democracy and Individual Autonomy",
        content: (
            <div>
                <p>Direct democracy emphasizes the role of the individual in political decision-making. By providing each citizen with an equal voice, it can serve to promote individual autonomy and empowerment.</p>
                <p>However, this focus on individual decision-making can also raise questions about the role of expertise in democratic deliberation. Ensuring that citizens are well-informed is a critical aspect of making direct democracy work effectively.</p>
            </div>
        ),
        date: "2023-05-28"
    },
    {
        title: "Community and Direct Democracy",
        content: (
            <div>
                <p>Direct democracy can foster a sense of community and collective ownership of political decisions. By involving all citizens in decision-making, it can create a sense of shared responsibility and collective action.</p>
                <p>However, fostering this sense of community can be challenging in diverse societies. Building mutual understanding and finding common ground are key tasks for those seeking to implement direct democracy in practice.</p>
            </div>
        ),
        date: "2023-05-28"
    },
    {
        title: "Digital Tools: A Boon for Direct Democracy?",
        content: (
            <div>
                <p>Technology provides new avenues for participation, potentially strengthening direct democracy. From online voting systems to digital platforms for policy discussions, the internet opens up numerous possibilities for more inclusive and efficient decision-making processes.</p>
                <p>However, these advancements come with challenges such as the risk of digital exclusion and cybersecurity concerns. Ensuring equal access and data security are critical for the successful integration of technology in direct democracy.</p>
            </div>
        ),
        date: "2023-05-27"
    },
    {
        title: "Social Media and Direct Democracy: A Double-edged Sword",
        content: (
            <div>
                <p>Social media platforms can facilitate direct engagement in democratic processes, bringing forth a diversity of voices. They can serve as venues for discussion, mobilization, and direct action, fostering an interactive form of democracy.</p>
                <p>Nevertheless, issues such as disinformation, polarization, and online harassment pose significant threats. Balancing the potential benefits and drawbacks of social media is a major challenge at the intersection of direct democracy and technology.</p>
            </div>
        ),
        date: "2023-05-27"
    },
    {
        title: "AI and Direct Democracy: Future Possibilities",
        content: (
            <div>
                <p>Could artificial intelligence (AI) support direct democratic processes? AI technologies, such as machine learning and natural language processing, could potentially analyze vast amounts of data to identify public sentiments and major policy issues.</p>
                <p>Despite the potential, the use of AI in democracy raises crucial ethical and technical questions, including issues of transparency, accountability, and the risk of algorithmic bias. Navigating these challenges is essential for the integration of AI into democratic processes.</p>
            </div>
        ),
        date: "2023-05-27"
    }
];