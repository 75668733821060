// src/pages/VotePilotPage.tsx
import React, {useState, useEffect, CSSProperties, FC} from 'react'
import {FaApple} from 'react-icons/fa'
import {SiGoogleplay} from 'react-icons/si'
import ImageCarousel from '../components/ImageCarousel' // Adjust the path as needed
import logo from "../images/Logo2.jpg" // Assuming you want to include the logo
import {useLocation} from 'react-router-dom'

const VotePilotPage: FC = () =>
{
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() =>
    {
        const handleResize = () =>
        {
            setIsMobile(window.innerWidth <= 768)
        }

        handleResize()
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const location = useLocation()


    useEffect(() =>
    {
        window.scrollTo(0, 0) // Resets the scroll position to the top
    }, [location])

    const handleDownload = (platform: 'ios' | 'android') =>
    {
        const urls: Record<'ios' | 'android', string> = {
            ios: 'https://apps.apple.com/us/app/votepilot/id6670745730',
            android: 'https://play.google.com/store/apps/details?id=com.directdemocracy.vote_pilot&pcampaignid=web_share',
        }
        window.open(urls[platform], '_blank')
    }

    // Styles
    const styles: {[key: string]: CSSProperties} = {
        container: {
            maxWidth: '800px',
            margin: '0 auto',
            padding: '25px 20px',
            fontFamily: 'Helvetica Neue, sans-serif',
            color: '#000',
        },
        header: {
            textAlign: 'center',
            marginBottom: '30px',
        },
        logo: {
            width: '80px',
            height: '80px',
            marginBottom: '20px',
        },
        title: {
            fontSize: isMobile ? '28px' : '36px',
            fontWeight: 'bold',
            marginBottom: '10px',
        },
        subtitle: {
            fontSize: isMobile ? '18px' : '24px',
            marginBottom: isMobile ? '15px' : '20px',
            color: '#555',
        },
        downloadButtons: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: '15px',
            marginTop: isMobile ? '10px' : '20px',
            flexDirection: isMobile ? 'row' : 'row', // Ensure row direction
        },
        buttonWrapper: {
            flex: '1 1 auto', // Allow buttons to take up available space
            maxWidth: isMobile ? '45%' : '200px', // Adjust max width for better fit on mobile
            display: 'flex',
            justifyContent: 'center',
            minWidth: isMobile ? '150px' : '200px', // Ensure buttons don't get too small
        },
        button: {
            padding: isMobile ? '10px 15px' : '12px 24px',
            fontSize: isMobile ? '14px' : '18px',
            borderRadius: isMobile ? '20px' : '24px',
            backgroundColor: '#000', // Black background
            color: '#fff', // White text
            border: 'none',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%', // Make button take full width of its wrapper
        },
        icon: {
            marginRight: '8px',
            fontSize: isMobile ? '16px' : '20px',
        },
        carouselSection: {
            marginTop: '40px',
            marginBottom: '0px',
        },
    }

    return (
        <div style={styles.container}>
            <header style={styles.header}>
                <h1 style={styles.title}>VotePilot</h1>
                <p style={styles.subtitle}>The Future of Democracy</p>
                <div style={styles.downloadButtons}>
                    <div style={styles.buttonWrapper}>
                        <button
                            onClick={() => handleDownload('ios')}
                            aria-label="Download on the App Store"
                            style={styles.button}
                        >
                            <FaApple style={styles.icon} />
                            App Store
                        </button>
                    </div>
                    <div style={styles.buttonWrapper}>
                        <button
                            onClick={() => handleDownload('android')}
                            aria-label="Get it on Google Play"
                            style={styles.button}
                        >
                            <SiGoogleplay style={styles.icon} />
                            Google Play
                        </button>
                    </div>
                </div>
            </header>

            {/* Image Carousel Section */}
            <section style={styles.carouselSection}>
                <ImageCarousel />
            </section>
        </div>
    )
}

export default VotePilotPage